import _ from 'lodash';

import React, { createContext, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Listing, ServiceOffer, ServiceOfferDetails, Shooting, DistanceFeeDetail } from 'models';
import { saveShootingDraft, updateShooting } from 'apis';
import { useAlert, useAllBrokers, useAuth, useShooting } from 'hooks';
import {
  updateShooting as updateStoreShooting,
  hideModal,
  fetchShootingAsync,
} from 'state/actions';
import { IShootingContextProps } from 'types';
import { ALERT, MODAL, SERVICE_OFFER_CATEGORY_ICON } from 'config/constants';
import { isEmailValid } from 'utils';

export const ShootingContext = createContext({
  brokers: [],

  listing: null,
  updateListingFields: _.noop,

  shooting: null,
  updateShootingFields: _.noop,
  saveShooting: _.noop,

  shouldChooseTimeslot: _.noop,

  resetContext: _.noop,

  packages: [],
  setPackages: _.noop,

  distanceFee: null,
  setDistanceFee: _.noop,

  longDistanceServiceOffer: null,
  setLongDistanceServiceOffer: _.noop,
  serviceOffers: [],
  setServiceOffers: _.noop,

  amenities: [],
  setAmenities: _.noop,

  addressFound: false,
  setAddressFound: _.noop,

  isServicable: true,
  setIsServicable: _.noop,

  availabilities: [],
  setAvailabilities: _.noop,

  isPropertySizeConfirmed: false,
  setPropertySizeConfirmed: _.noop,

  validateLocationStep: _.noop,
  validatePropertyStep: _.noop,
  validatePackageStep: _.noop,
  validateClientInfoStep: _.noop,

  isFake: false,
  isEditMode: false,

  showRecommendationModal: false,
  setShowRecommendationModal: _.noop,
  recommendedServiceOffers: [],
  ignoreRecommendation: false,
  setIgnoreRecommendation: _.noop,
  ignoreAutoSaisie: false,
  setIgnoreAutoSaisie: _.noop,
  isSeasonalSOSelected: false,
  setIsSeasonalSOSelected: _.noop,
  selectedPhotoCountOption: null,
  setSelectedPhotoCountOption: _.noop,
  selectedDronePhotoCountOption: 0,
  setSelectedDronePhotoCountOption: _.noop,
  defaultPackagesPhotoCountOptions: [],
  defaultPackagesDronePhotoCountOptions: [],
  defaultSeasonalPhotoCountOptions: [],
  defaultSeasonalDronePhotoCountOptions: [],
  allDronePhotoCountOptions: [],
  allSeasonalPhotoCountOptions: [],
  allSeasonalDronePhotoCountOptions: [],
  photoCountOptions: [],
  dronePhotoCountOptions: [],
  setPhotoCountOptions: _.noop,
  setDronePhotoCountOptions: _.noop,
  selectedPackageType: null,
  setSelectedPackageType: _.noop,
} as IShootingContextProps);

interface Props {
  shootingId: number;
  isChanged: boolean;
  setIsChanged: (_: boolean) => void;
  children: any;
  bookAnotherShoot: boolean;
}

function ShootingContextProvider({
  shootingId,
  isChanged,
  setIsChanged,
  children,
  bookAnotherShoot,
}: Props) {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { user, token } = useAuth();
  const { displayAlert } = useAlert();

  const { brokers } = useAllBrokers(user, token);
  const { shooting: selectedShooting } = useShooting(token, shootingId);

  const [listing, setListing] = useState(new Listing());
  const [shooting, setShooting] = useState(new Shooting());

  const [packages, setPackages] = useState([]);
  const [distanceFee, setDistanceFee] = useState<DistanceFeeDetail>(null);
  const [longDistanceServiceOffer, setLongDistanceServiceOffer] =
    useState<ServiceOfferDetails>(null);
  const [serviceOffers, setServiceOffers] = useState([]);
  const [recommendedServiceOffers, setRecommendedServiceOffers] = useState([]);

  const [amenities, setAmenities] = useState([]);

  const [addressFound, setAddressFound] = useState(!!shootingId);
  const [isServicable, setIsServicable] = useState(true);
  const [availabilities, setAvailabilities] = useState([]);

  const [showRecommendationModal, setShowRecommendationModal] = useState(false);
  const [ignoreRecommendation, setIgnoreRecommendation] = useState(false);
  const [ignoreAutoSaisie, setIgnoreAutoSaisie] = useState(false);
  const [isPropertySizeConfirmed, setPropertySizeConfirmed] = useState(false);

  const [is_same_day_delivery_checked, setIsSameDayDeliveryChecked] = useState(false);
  const [direct_drive, setDirectDrive] = useState(false);
  const [isSeasonalSOSelected, setIsSeasonalSOSelected] = useState(false);
  const [selectedPhotoCountOption, setSelectedPhotoCountOption] = useState(0);
  const [selectedDronePhotoCountOption, setSelectedDronePhotoCountOption] = useState(0);
  const [defaultPackagesPhotoCountOptions, setDefaultPackagesPhotoCountOptions] = useState([]);
  const [defaultPackagesDronePhotoCountOptions, setDefaultPackagesDronePhotoCountOptions] =
    useState([]);
  const [defaultSeasonalPhotoCountOptions, setDefaultSeasonalPhotoCountOptions] = useState([]);
  const [defaultSeasonalDronePhotoCountOptions, setDefaultSeasonalDronePhotoCountOptions] =
    useState([]);
  const [allDronePhotoCountOptions, setAllDronePhotoCountOptions] = useState([]);
  const [allSeasonalPhotoCountOptions, setAllSeasonalPhotoCountOptions] = useState([]);
  const [allSeasonalDronePhotoCountOptions, setAllSeasonalDronePhotoCountOptions] = useState([]);
  const [photoCountOptions, setPhotoCountOptions] = useState([]);
  const [dronePhotoCountOptions, setDronePhotoCountOptions] = useState([]);
  const {
    square_footage,
    broker_present,
    client_present,
    key_box_number,
    shooting_params,
    shootingPackage,
    service_offers,
  } = shooting;
  const signatureServiceOffers = serviceOffers.filter((m: ServiceOffer) => {
    return m.serviceOffer.category.icon_name === SERVICE_OFFER_CATEGORY_ICON.SIGNATURE;
  });
  const [selectedPackageType, setSelectedPackageType] = useState(
    SERVICE_OFFER_CATEGORY_ICON.SIGNATURE,
  );
  const isEditMode = !!shootingId;
  const { client_email } = shooting_params;
  const updateShootingFields = (data: any, isOverwrite: boolean = false) => {
    const _shooting = _.cloneDeep(shooting);
    if (isOverwrite) {
      setShooting(_.assign(_shooting, data));
    } else {
      setShooting(_.merge(_shooting, data));
    }
    setIsChanged(true);
  };
  useEffect(() => {
    if (selectedShooting) {
      const _selectedShooting = _.cloneDeep(selectedShooting);
      setShooting(_selectedShooting);
      if (_selectedShooting.direct_drive) {
        setDirectDrive(_selectedShooting.direct_drive);
      }
      const foundSameDayServiceOffer = _selectedShooting.service_offers.find((m: ServiceOffer) => {
        return m.serviceOffer.category.icon_name === SERVICE_OFFER_CATEGORY_ICON.SAME_DAY_DELIVERY;
      });
      if (foundSameDayServiceOffer) {
        setIsSameDayDeliveryChecked(true);
      }
      setListing(selectedShooting.listing);
      if (bookAnotherShoot) {
        updateShootingFields(new Shooting(), true);
      }
    }
  }, [selectedShooting]);

  useEffect(() => {
    if (shootingPackage?.id) {
      setSelectedPhotoCountOption(
        shootingPackage?.hdr_photo_count - shootingPackage?.drone_photo_count,
      );
      setSelectedDronePhotoCountOption(shootingPackage?.drone_photo_count);
    }
  }, [shootingPackage]);

  useEffect(() => {
    let recommendedServiceOffers = serviceOffers.filter((s: ServiceOffer) => {
      return s.serviceOffer?.is_recommended;
    });
    recommendedServiceOffers = recommendedServiceOffers.filter((s: ServiceOffer) => {
      return !service_offers.includes(s);
    });
    setRecommendedServiceOffers(recommendedServiceOffers);
    if (recommendedServiceOffers.length) {
      setShowRecommendationModal(true);
    }
    const seasonalServiceOffers = serviceOffers.filter((m: ServiceOffer) => {
      return (
        m.serviceOffer.category.icon_name === SERVICE_OFFER_CATEGORY_ICON.SEASONAL ||
        m.serviceOffer.category.icon_name === SERVICE_OFFER_CATEGORY_ICON.DRONE
      );
    });
    let seasonalPhotoCounts = [];
    let seasonalDronePhotoCounts = [0];
    seasonalServiceOffers.forEach((so: ServiceOffer) => {
      const foundOnlySeasonalDrone =
        so.serviceOffer.items.length === 1 &&
        so.serviceOffer.items[0].service_offer_item.icon_name === SERVICE_OFFER_CATEGORY_ICON.DRONE;
      if (foundOnlySeasonalDrone && !seasonalPhotoCounts.includes(0)) {
        seasonalPhotoCounts.push(0);
      }
      so.serviceOffer.items.forEach((item) => {
        if (
          item?.service_offer_item?.icon_name === SERVICE_OFFER_CATEGORY_ICON.SEASONAL &&
          !seasonalPhotoCounts.includes(item?.service_offer_item?.hdr_photo_count)
        ) {
          seasonalPhotoCounts.push(item?.service_offer_item?.hdr_photo_count);
        }
        if (
          item?.service_offer_item?.icon_name === SERVICE_OFFER_CATEGORY_ICON.DRONE &&
          !seasonalDronePhotoCounts.includes(item?.service_offer_item?.hdr_photo_count)
        ) {
          seasonalDronePhotoCounts.push(item?.service_offer_item?.hdr_photo_count);
        }
      });
    });
    seasonalPhotoCounts = seasonalPhotoCounts.sort((a, b) => {
      return a - b;
    });
    seasonalDronePhotoCounts = seasonalDronePhotoCounts.sort((a, b) => {
      return a - b;
    });
    setDefaultSeasonalPhotoCountOptions(seasonalPhotoCounts);
    setAllSeasonalPhotoCountOptions(seasonalPhotoCounts);
    setDefaultSeasonalDronePhotoCountOptions(seasonalDronePhotoCounts);
    setAllSeasonalDronePhotoCountOptions(seasonalDronePhotoCounts);
    if (isSeasonalSOSelected) {
      setPhotoCountOptions(seasonalPhotoCounts);
    }
    setSelectedPackageType(
      (shootingPackage && shootingPackage.id) || !signatureServiceOffers.length
        ? SERVICE_OFFER_CATEGORY_ICON.SHOOTING_PACKAGE
        : SERVICE_OFFER_CATEGORY_ICON.SIGNATURE,
    );
  }, [serviceOffers]);
  useEffect(() => {
    const mergedPhotoCountOptions = _.union(
      defaultSeasonalDronePhotoCountOptions,
      defaultPackagesDronePhotoCountOptions,
    );
    setAllDronePhotoCountOptions(mergedPhotoCountOptions);
    setDronePhotoCountOptions(mergedPhotoCountOptions);
  }, [serviceOffers, packages]);
  useEffect(() => {
    const _packages = _.cloneDeep(packages);
    let dronePhotoCounts = [];
    let photoCounts = [0];
    for (let i = 0; i < _packages.length; i++) {
      const _package = _packages[i];
      if (!photoCounts.includes(_package.hdr_photo_count - _package.drone_photo_count)) {
        photoCounts.push(_package.hdr_photo_count - _package.drone_photo_count);
      }
      if (!dronePhotoCounts.includes(_package.drone_photo_count)) {
        dronePhotoCounts.push(_package.drone_photo_count);
      }
    }
    photoCounts = photoCounts.sort((a, b) => {
      return a - b;
    });
    dronePhotoCounts = dronePhotoCounts.sort((a, b) => {
      return a - b;
    });
    setDefaultPackagesPhotoCountOptions(photoCounts);
    setDefaultPackagesDronePhotoCountOptions(dronePhotoCounts);
  }, [packages]);
  const validateLocationStep = () => {
    if (!listing.lat || !listing.lng) {
      displayAlert(
        ALERT.ERROR,
        intl.formatMessage({ id: 'shooting.create.messages.invalidAddress' }),
      );
      return false;
    }
    if (!listing.civic_number) {
      displayAlert(
        ALERT.ERROR,
        intl.formatMessage({ id: 'shooting.create.messages.specifyCivicNumber' }),
      );
      return false;
    }
    if (listing.property_type === Listing.TYPE_ENUM.PLEX && !shooting.plex_unit_count) {
      displayAlert(
        ALERT.ERROR,
        intl.formatMessage({ id: 'shooting.create.messages.specifyPlexUnitCount' }),
      );
      return false;
    }
    if (listing.postalcode?.length < 3) {
      displayAlert(
        ALERT.ERROR,
        intl.formatMessage({ id: 'shooting.create.messages.invalidPostalCode' }),
      );
      return false;
    }
    if (!isServicable) {
      displayAlert(
        ALERT.ERROR,
        intl.formatMessage({ id: 'shooting.create.messages.unserviceable' }),
      );
      return false;
    }
    return true;
  };

  const validatePropertyStep = () => {
    if (!shooting_params.room_count) {
      displayAlert(
        ALERT.ERROR,
        intl.formatMessage({ id: 'shooting.create.messages.missingPropertySizeConfirmation' }),
      );
      return false;
    }
    if (!broker_present && !client_present && !key_box_number) {
      displayAlert(
        ALERT.ERROR,
        intl.formatMessage({ id: 'shooting.create.messages.missingEnterPropertyInfo' }),
      );
      return false;
    }
    return true;
  };

  const validatePackageStep = () => {
    const validServiceOffers = service_offers.filter((m: ServiceOffer) => {
      return !!m.serviceOffer?.can_be_standalone;
    });
    if (
      (!shootingPackage || (shootingPackage && shootingPackage.id === undefined)) &&
      !validServiceOffers.length
    ) {
      displayAlert(
        ALERT.ERROR,
        intl.formatMessage({ id: 'shooting.create.messages.missingShootingPackage' }),
      );
      return false;
    }
    return true;
  };

  const validateClientInfoStep = () => {
    if (!!client_email && !isEmailValid(client_email)) {
      displayAlert(
        ALERT.ERROR,
        intl.formatMessage({ id: 'shooting.create.messages.invalidEmailAddress' }),
      );
      return false;
    }
    return true;
  };

  const updateListingFields = (data: any, isOverwrite: boolean = false) => {
    const _listing = _.cloneDeep(listing);

    if (isOverwrite) {
      setListing(_.assign(_listing, data));
    } else {
      setListing(_.merge(_listing, data));
    }

    setIsChanged(true);
  };

  const saveShooting = async () => {
    if (!isChanged) {
      dispatch(hideModal(MODAL.SHOOTING_EDIT));
      return;
    }
    try {
      let newShooting;
      if (!shooting.state || shooting.state === Shooting.STATE_ENUM.draft) {
        newShooting = await saveShootingDraft(token, shooting, listing);
        dispatch(fetchShootingAsync(token, newShooting.id));
        dispatch(hideModal(MODAL.SHOOTING_EDIT));
      } else {
        newShooting = await updateShooting(token, shooting, listing);
        dispatch(updateStoreShooting(newShooting));
      }
      setIsChanged(false);

      displayAlert(
        ALERT.SUCCESS,
        intl.formatMessage({ id: 'shooting.create.messages.savedShootingSuccess' }),
      );
    } catch (error) {
      displayAlert(
        ALERT.ERROR,
        intl.formatMessage({ id: 'shooting.create.messages.savedShootingError' }),
      );
    }
  };

  const shouldChooseTimeslot = () => {
    const { shootingPackage, service_offers, shooting_params, square_footage } = shooting;
    const {
      shootingPackage: oShootingPackage,
      service_offers: oServiceOffers,
      shooting_params: oShootingParams,
      square_footage: oSquareFootage,
    } = selectedShooting || {};

    return (
      !_.isEqual(shootingPackage, oShootingPackage) ||
      !_.isEqual(service_offers, oServiceOffers) ||
      !_.isEqual(shooting_params.room_count, oShootingParams.room_count)
    );
  };

  const resetContext = () => {
    updateListingFields(new Listing(), true);
    updateShootingFields(new Shooting(), true);

    setIsChanged(false);
    setAddressFound(false);
  };

  const contextData = {
    brokers,

    listing,
    updateListingFields,

    shooting,
    updateShootingFields,
    saveShooting,

    shouldChooseTimeslot,

    resetContext,

    packages,
    setPackages,

    distanceFee,
    setDistanceFee,

    longDistanceServiceOffer,
    setLongDistanceServiceOffer,
    serviceOffers,
    setServiceOffers,

    amenities,
    setAmenities,

    addressFound,
    setAddressFound,

    isServicable,
    setIsServicable,

    availabilities,
    setAvailabilities,

    showRecommendationModal,
    setShowRecommendationModal,
    recommendedServiceOffers,
    ignoreRecommendation,
    setIgnoreRecommendation,

    ignoreAutoSaisie,
    setIgnoreAutoSaisie,

    isPropertySizeConfirmed,
    setPropertySizeConfirmed,

    is_same_day_delivery_checked,
    setIsSameDayDeliveryChecked,

    direct_drive,
    setDirectDrive,

    validateLocationStep,
    validatePropertyStep,
    validatePackageStep,
    validateClientInfoStep,

    isEditMode,
    isFake: false,
    isSeasonalSOSelected,
    setIsSeasonalSOSelected,
    selectedPhotoCountOption,
    setSelectedPhotoCountOption,
    selectedDronePhotoCountOption,
    setSelectedDronePhotoCountOption,
    defaultPackagesPhotoCountOptions,
    defaultPackagesDronePhotoCountOptions,
    defaultSeasonalPhotoCountOptions,
    defaultSeasonalDronePhotoCountOptions,
    allDronePhotoCountOptions,
    allSeasonalPhotoCountOptions,
    allSeasonalDronePhotoCountOptions,
    photoCountOptions,
    dronePhotoCountOptions,
    setPhotoCountOptions,
    setDronePhotoCountOptions,
    selectedPackageType,
    setSelectedPackageType,
  } as IShootingContextProps;

  return <ShootingContext.Provider value={contextData}>{children}</ShootingContext.Provider>;
}

export default ShootingContextProvider;
