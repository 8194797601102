import User from './User';

class PostProductionNote {
  id: number;

  user_id: number;

  shooting_service_offer_id?: number;

  shooting_id?: number;

  timestamp: string;

  note: string;

  user?: User;

  constructor(data: object = {}) {
    Object.assign(this, data);

    if (this.user) {
      this.user = new User(this.user);
    }
  }
}

export default PostProductionNote;
