import { images } from 'assets/images';
import ServiceOffer from './ServiceOffer';

class Ticket {
  id: number;

  picture_id: number;

  x: number;

  y: number;

  width: number;

  height: number;

  shape: string;

  description: string;

  created_at: string;

  state: number;

  resolved: boolean;

  constructor(data: object = {}) {
    Object.assign(this, data);
  }

  getStatusTitle(isEn: boolean): string {
    if (isEn) {
      return ServiceOffer.STATE_DISPLAY_NAME.en[this.state];
    }
    return ServiceOffer.STATE_DISPLAY_NAME.fr[this.state];
  }

  get statusIcon(): string {
    switch (this.state) {
      case ServiceOffer.STATE_ENUM.NOT_STARTED:
        return images.not_started;
      case ServiceOffer.STATE_ENUM.ORDERED:
        return images.ongoing;
      case ServiceOffer.STATE_ENUM.ONGOING:
        return images.ongoing;
      case ServiceOffer.STATE_ENUM.COMPLETED:
        return images.completed;
      case ServiceOffer.STATE_ENUM.DELIVERED:
        return images.delivered;
      case ServiceOffer.STATE_ENUM.CANCELLED:
        return images.cancelled;
      default:
        return images.not_started;
    }
  }
}

export default Ticket;
