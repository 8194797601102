import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import {
  addAvailabilityForTechsAsync,
  fetchUsersCalendarAsync,
  fetchUserScheduleForTimeperiodAsync,
  removeAvailabilityForTechsAsync,
  addUnavailabilityForTechsAsync,
  removeUnavailabilityForTechsAsync,
} from 'state/actions';
import { LOADING, IDLES } from 'state/constants';

export const useScheduleCalendar = (token: string) => {
  const {
    modalType,
    currentView,
    currentDate,
    currentMonth,
    events,
    selectedPhotographers,
    availabilityToBeDeleted,
    unavailabilityToBeDeleted,
    shootsToDisplay,
  } = useSelector((state: any) => {
    return state.scheduleCalendar;
  });

  return {
    modalType,
    currentView,
    currentDate,
    currentMonth,
    events,
    selectedPhotographers,
    availabilityToBeDeleted,
    unavailabilityToBeDeleted,
    shootsToDisplay,
  };
};

export const useUsersCalendar = (
  token: string,
  isAdmin: boolean,
  userId: number,
  startDate: string,
  endDate: string,
) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(LOADING.NONE);
  const [state, setState] = useState({
    adding: LOADING.NONE,
    added: false,
  });

  const { schedule, currentView, currentMonth } = useSelector((state: any) => {
    return state.scheduleCalendar;
  });

  const loadUsersCalendar = async () => {
    const isIdle = IDLES.includes(loading);
    if (token && isIdle) {
      setLoading(LOADING.STARTED);
      dispatch(fetchUsersCalendarAsync(token, startDate, endDate));
      setLoading(LOADING.FINISHED);
    }
  };
  const loadUserCalendar = async () => {
    const isIdle = IDLES.includes(loading);
    if (token && isIdle) {
      setLoading(LOADING.STARTED);
      await dispatch(fetchUserScheduleForTimeperiodAsync(token, userId, startDate, endDate));
      setLoading(LOADING.FINISHED);
    }
  };

  useEffect(() => {
    if (isAdmin) {
      loadUsersCalendar();
    } else {
      loadUserCalendar();
    }
  }, [token, currentMonth, currentView]);
  return { schedule, loading };
};

export const useAddAvailability = (token: string) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    addingAvailability: LOADING.NONE,
    addedAvailability: false,
  });

  const { addedAvailabilityStatus } = useSelector((state: any) => {
    return state.scheduleCalendar;
  });

  const addAvailability = async (token: string, techs: any, selectedTechsObj: any, dates: any) => {
    const isIdle = IDLES.includes(state.addingAvailability);
    if (token && isIdle) {
      setState({ addedAvailability: false, addingAvailability: LOADING.STARTED });
      await dispatch(addAvailabilityForTechsAsync(token, techs, selectedTechsObj, dates));
      setState({ addedAvailability: true, addingAvailability: LOADING.FINISHED });
    }
  };

  return { addAvailability, ...state, addedAvailabilityStatus };
};

export const useRemoveAvailability = (token: string) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    removingAvailability: LOADING.NONE,
    removedAvailability: false,
  });

  const { removedAvailabilityStatus } = useSelector((state: any) => {
    return state.scheduleCalendar;
  });

  const removeAvailability = async (
    token: string,
    tech_id: number,
    startTime: string,
    availability_id: number,
  ) => {
    const isIdle = IDLES.includes(state.removingAvailability);
    if (token && isIdle) {
      setState({ removedAvailability: false, removingAvailability: LOADING.STARTED });
      await dispatch(removeAvailabilityForTechsAsync(token, tech_id, startTime, availability_id));
      setState({ removedAvailability: true, removingAvailability: LOADING.FINISHED });
    }
  };

  return { removeAvailability, ...state, removedAvailabilityStatus };
};

export const useAddUnavailability = (token: string) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    addingUnavailability: LOADING.NONE,
    addedUnavailability: false,
  });

  const { addedUnavailabilityStatus } = useSelector((state: any) => {
    return state.scheduleCalendar;
  });

  const addUnavailability = async (
    tech: any,
    start: string,
    end: string,
    unavailability_id: number,
    reason: string,
  ) => {
    const isIdle = IDLES.includes(state.addingUnavailability);
    if (token && isIdle) {
      setState({ addedUnavailability: false, addingUnavailability: LOADING.STARTED });
      await dispatch(
        addUnavailabilityForTechsAsync(token, tech, start, end, unavailability_id, reason),
      );
      setState({ addedUnavailability: true, addingUnavailability: LOADING.FINISHED });
    }
  };

  return { addUnavailability, ...state, addedUnavailabilityStatus };
};

export const useRemoveUnavailability = (token: string) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    removingUnavailability: LOADING.NONE,
    removedUnavailability: false,
  });

  const { removedUnavailabilityStatus } = useSelector((state: any) => {
    return state.scheduleCalendar;
  });

  const removeUnavailability = async (unavailability_id: number) => {
    const isIdle = IDLES.includes(state.removingUnavailability);
    if (token && isIdle) {
      setState({ removedUnavailability: false, removingUnavailability: LOADING.STARTED });
      await dispatch(removeUnavailabilityForTechsAsync(token, unavailability_id));
      setState({ removedUnavailability: true, removingUnavailability: LOADING.FINISHED });
    }
  };

  return { removeUnavailability, ...state, removedUnavailabilityStatus };
};
