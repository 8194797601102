import { useLanguage } from 'hooks';
import { ENG } from 'config/constants';
import LogAction from './LogAction';
import ServiceOffer from './ServiceOffer';
import User from './User';
import Shooting from './Shooting';

class ShootingLog {
  id: number;

  user_id: number;

  shooting_id: number;

  action_id: number;

  changed_value: { from: number; to: number } = null;

  timestamp: string;

  user?: User;

  shooting_service_offer_id?: number;

  shootingServiceOffer?: ServiceOffer;

  action?: LogAction;

  static LOG_ACTION = {
    change_shooting_package: 1,
    change_shooting_status: 2,
    add_service_offer: 3,
    change_service_offer_status: 4,
    change_shooting_review_status: 5,
  };

  constructor(data: object = {}) {
    Object.assign(this, data);
    if (this.user) {
      this.user = new User(this.user);
    }

    if (this.shooting_service_offer_id) {
      this.shootingServiceOffer = new ServiceOffer(this.shootingServiceOffer);
    }
  }

  get actorFullName(): string {
    if (this.user && this.user.firstname && this.user.lastname) {
      return `${this.user.firstname} ${this.user.lastname}`;
    }
    return 'NA';
  }

  static changedValueToText(action_id: number, value: any, isEn: boolean): string {
    const language = isEn ? 'en' : 'fr';
    switch (action_id) {
      case ShootingLog.LOG_ACTION.change_shooting_package:
        return isEn ? value.display_name_en : value.display_name_fr;
      case ShootingLog.LOG_ACTION.change_shooting_status:
        return Shooting.STATE_DISPLAY_NAME[language][value];
      case ShootingLog.LOG_ACTION.change_service_offer_status:
        return ServiceOffer.STATE_DISPLAY_NAME[language][value];
      case ShootingLog.LOG_ACTION.change_shooting_review_status:
        if (value) {
          return isEn ? 'ready for review' : 'prêt pour la revue';
        }
        return isEn ? 'not ready for review' : 'non prêt pour la revue';
      default:
        return value;
    }
  }
}

export default ShootingLog;
