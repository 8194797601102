import { IServiceOfferTypeValueMap } from 'types';
import { images } from 'assets/images';
import ServiceOfferDetails from './ServiceOfferDetails';
import ShootingLog from './ShootingLog';
import Shooting from './Shooting';
import PostProductionNote from './PostProductionNote';

class ServiceOffer {
  id?: number;

  broker_note?: string;

  serviceOffer?: ServiceOfferDetails = new ServiceOfferDetails();

  service_offer_id?: number;

  shooting_id?: number;

  is_child?: boolean;

  type_value_map?: IServiceOfferTypeValueMap;

  url?: string;

  youtube_video_id?: string;

  is_public?: boolean;

  listing_video_id?: number;

  created_at?: string;

  logs?: ShootingLog[] = [];

  shooting?: Shooting;

  notes?: PostProductionNote[] = [];

  internal_note?: string;

  state: number;

  pp_tech_id: number;

  static TYPE = {
    BOOLEAN: 1,
    NUMBER: 2,
  };

  static STATE_ENUM = {
    NOT_STARTED: 0,
    ORDERED: 1,
    ONGOING: 2,
    COMPLETED: 3,
    DELIVERED: 4,
    CANCELLED: 5,
  };

  static STATE_DISPLAY_NAME = {
    en: {
      0: 'not started',
      1: 'ordered',
      2: 'on going',
      3: 'completed',
      4: 'delivered',
      5: 'cancelled',
    },
    fr: {
      0: 'pas commencée',
      1: 'commandée',
      2: 'en cours',
      3: 'complétée',
      4: 'livrée',
      5: 'cancellée',
    },
  };

  constructor(data: object = {}) {
    Object.assign(this, data);

    if (this.serviceOffer) {
      this.serviceOffer = new ServiceOfferDetails(this.serviceOffer);
    }

    if (this.notes) {
      this.notes = this.notes.map((note: PostProductionNote) => {
        return new PostProductionNote(note);
      });
    }
  }

  serviceOfferName(isEn: boolean): string {
    if (isEn) {
      return this.serviceOffer.display_name;
    }
    return this.serviceOffer.display_name_fr;
  }

  getStatusTitle(isEn: boolean): string {
    if (isEn) {
      return ServiceOffer.STATE_DISPLAY_NAME.en[this.state];
    }
    return ServiceOffer.STATE_DISPLAY_NAME.fr[this.state];
  }

  get statusIcon(): string {
    switch (this.state) {
      case ServiceOffer.STATE_ENUM.NOT_STARTED:
        return images.not_started;
      case ServiceOffer.STATE_ENUM.ORDERED:
        return images.ongoing;
      case ServiceOffer.STATE_ENUM.ONGOING:
        return images.ongoing;
      case ServiceOffer.STATE_ENUM.COMPLETED:
        return images.completed;
      case ServiceOffer.STATE_ENUM.DELIVERED:
        return images.delivered;
      case ServiceOffer.STATE_ENUM.CANCELLED:
        return images.cancelled;
      default:
        return images.not_started;
    }
  }
}

export default ServiceOffer;
