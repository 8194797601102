import _ from 'lodash';

import React, { createContext, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth, useProfile } from 'hooks';
import { IProfileContextProps } from 'types';
import { PROFILE_ITEMS } from 'config/constants';
import { LOADING } from 'state/constants';
import { updateProfileAsync } from 'state/actions';
import { isPasswordValid } from 'utils';

export const ProfileContext = createContext({
  email: '',
  mobile: null,
  updateMobile: _.noop,
  isMobileChange: false,
  office: null,
  updateOffice: _.noop,
  isOfficeChange: false,
  titleNum: -1,
  updateTitleNum: _.noop,
  isTitleNumChange: false,
  customTitle: '',
  updateCustomTitle: _.noop,
  isCustomTitleChange: false,
  bannerId: -1,
  updateBannerId: _.noop,
  isBannerIdChange: false,
  franchiseId: -1,
  updateFranchiseId: _.noop,
  isFranchiseIdChange: false,
  centrisCode: '',
  updateCentrisCode: _.noop,
  automatic_saisie: false,
  updateAutomaticSaisie: _.noop,
  isCentrisCodeChange: false,
  qaciqCode: '',
  updateQaciqCode: _.noop,
  isQaciqCodeChange: false,
  centrisCodeStatus: -1,
  updateCentrisCodeStatus: _.noop,
  isCentrisCodeStatusChange: false,
  fbPixelId: null,
  updateFBPixelId: _.noop,
  isFBPixelIdChange: false,
  password: '',
  updatePassword: _.noop,
  isPasswordChange: false,
  rePassword: '',
  updateRePassword: _.noop,
  isRePasswordChange: false,
  isValidPassword: true,
  isValidRePassword: true,
  editStatus: false,
  updateEditStatus: _.noop,
  onSave: _.noop,
} as IProfileContextProps);

interface Props {
  children: any;
}

function ProfileContextProvider({ children }: Props) {
  const dispatch = useDispatch();

  const { user, token } = useAuth();
  const { loading, profile } = useProfile(token, user.email);

  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState(null);
  const [isMobileChange, setIsMobileChange] = useState(false);
  const [office, setOffice] = useState(null);
  const [isOfficeChange, setIsOfficeChange] = useState(false);
  const [titleNum, setTitleNum] = useState(-1);
  const [isTitleNumChange, setIsTitleNumChange] = useState(false);
  const [customTitle, setCustomTitle] = useState('');
  const [isCustomTitleChange, setIsCustomTitleChange] = useState(false);
  const [bannerId, setBannerId] = useState(-1);
  const [isBannerIdChange, setIsBannerIdChange] = useState(false);
  const [franchiseId, setFranchiseId] = useState(-1);
  const [isFranchiseIdChange, setIsFranchiseIdChange] = useState(false);
  const [centrisCode, setCentrisCode] = useState('');
  const [isCentrisCodeChange, setIsCentrisCodeChange] = useState(false);
  const [automatic_saisie, setAutomaticSaisie] = useState(false);
  const [qaciqCode, setQaciqCode] = useState('');
  const [isQaciqCodeChange, setIsQaciqCodeChange] = useState(false);
  const [fbPixelId, setFBPixelId] = useState(null);
  const [isFBPixelIdChange, setIsFBPixelIdChange] = useState(false);
  const [centrisCodeStatus, setCentrisCodeStatus] = useState(-1);
  const [isCentrisCodeStatusChange, setIsCentrisCodeStatusChange] = useState(false);
  const [password, setPassword] = useState('');
  const [isPasswordChange, setIsPasswordChange] = useState(false);
  const [rePassword, setRePassword] = useState('');
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [isValidRePassword, setIsValidRePassword] = useState(true);
  const [isRePasswordChange, setIsRePasswordChange] = useState(false);
  const [editStatus, setEditStatus] = useState(false);

  const updateMobile = (value: number) => {
    setMobile(value);
    setIsMobileChange(true);
  };

  const updateOffice = (value: number) => {
    setOffice(value);
    setIsOfficeChange(true);
  };

  const updateTitleNum = (value: number) => {
    setTitleNum(value);
    setIsTitleNumChange(true);
  };

  const updateCustomTitle = (value: string) => {
    setCustomTitle(value);
    setIsCustomTitleChange(true);
  };

  const updateBannerId = (value: number) => {
    setBannerId(value);
    setIsBannerIdChange(true);
  };

  const updateFranchiseId = (value: number) => {
    setFranchiseId(value);
    setIsFranchiseIdChange(true);
  };

  const updateCentrisCode = (value: string) => {
    setCentrisCode(value);
    setIsCentrisCodeChange(true);
  };

  const updateAutomaticSaisie = (value: boolean) => {
    setAutomaticSaisie(value);
  };

  const updateQaciqCode = (value: string) => {
    setQaciqCode(value);
    setIsQaciqCodeChange(true);
  };

  const updateFBPixelId = (value: number) => {
    setFBPixelId(value);
    setIsFBPixelIdChange(true);
  };

  const updateCentrisCodeStatus = (value: number) => {
    setCentrisCodeStatus(value);
    setIsCentrisCodeStatusChange(true);
  };

  const updatePassword = (value: string) => {
    setIsValidPassword(isPasswordValid(value));
    setPassword(value);
    setIsPasswordChange(true);
  };

  const updateRePassword = (value: string) => {
    setIsValidRePassword(isPasswordValid(value));
    setRePassword(value);
    setIsRePasswordChange(true);
  };

  const updateEditStatus = (value: boolean) => {
    setEditStatus(value);
  };

  const onSave = () => {
    const formdata = new FormData();
    formdata.append('email', email);

    if (isMobileChange) formdata.append(PROFILE_ITEMS.MOBILE, mobile.toString());
    if (isOfficeChange) formdata.append(PROFILE_ITEMS.OFFICE, office.toString());
    if (isTitleNumChange) formdata.append(PROFILE_ITEMS.TITLE, titleNum.toString());
    if (isCustomTitleChange) formdata.append(PROFILE_ITEMS.CUSTOM_TITLE, customTitle);
    if (isBannerIdChange) formdata.append(PROFILE_ITEMS.BANNER_ID, bannerId.toString());
    if (isFranchiseIdChange) formdata.append(PROFILE_ITEMS.FRANCHISE_ID, franchiseId.toString());
    if (isCentrisCodeChange) formdata.append(PROFILE_ITEMS.CENTRIS_CODE, centrisCode);
    if (!automatic_saisie !== profile.automatic_saisie) {
      formdata.append(PROFILE_ITEMS.AUTOMATIC_SAISIE, (!automatic_saisie).toString());
    }
    if (isQaciqCodeChange) formdata.append(PROFILE_ITEMS.QACIQ_CODE, qaciqCode);
    if (isFBPixelIdChange) formdata.append(PROFILE_ITEMS.FBPIXEL_ID, fbPixelId.toString());
    if (isPasswordChange && isRePasswordChange && password === rePassword) {
      formdata.append(PROFILE_ITEMS.PASSWORD, password);
    }
    setIsMobileChange(false);
    setIsOfficeChange(false);
    setIsTitleNumChange(false);
    setIsCustomTitleChange(false);
    setIsBannerIdChange(false);
    setIsFranchiseIdChange(false);
    setIsCentrisCodeChange(false);
    setIsQaciqCodeChange(false);
    setIsFBPixelIdChange(false);
    setIsPasswordChange(false);
    setIsRePasswordChange(false);
    dispatch(updateProfileAsync(token, formdata));
    setEditStatus(false);
  };

  useEffect(() => {
    if (password.length === 0) {
      setIsValidPassword(true && password === rePassword);
    } else {
      setIsValidPassword(isPasswordValid(password) && password === rePassword);
    }

    if (rePassword.length === 0) {
      setIsValidRePassword(true && password === rePassword);
    } else {
      setIsValidRePassword(isPasswordValid(rePassword) && password === rePassword);
    }
  }, [password, rePassword]);

  useEffect(() => {
    if (loading === LOADING.FINISHED && profile) {
      const {
        email,
        phone_number,
        office_phone_number,
        title_num,
        custom_title,
        banner_id,
        franchise_id,
        broker_code,
        oaciq_code,
        centris_data_status,
        pixel_id,
        automatic_saisie,
      } = profile;

      setEmail(email ?? '');
      setMobile(phone_number ?? null);
      setOffice(office_phone_number ?? null);
      setTitleNum(title_num ?? -1);
      setCustomTitle(custom_title ?? '');
      setBannerId(banner_id ?? -1);
      setFranchiseId(franchise_id ?? -1);
      setCentrisCode(broker_code ?? '');
      setQaciqCode(oaciq_code ?? '');
      setCentrisCodeStatus(centris_data_status ?? -1);
      setFBPixelId(pixel_id ?? null);
      setAutomaticSaisie(automatic_saisie ?? false);
    }
  }, [loading, profile]);

  const contextData = {
    email,
    mobile,
    updateMobile,
    isMobileChange,
    office,
    updateOffice,
    isOfficeChange,
    titleNum,
    updateTitleNum,
    isTitleNumChange,
    customTitle,
    updateCustomTitle,
    isCustomTitleChange,
    bannerId,
    updateBannerId,
    isBannerIdChange,
    franchiseId,
    updateFranchiseId,
    isFranchiseIdChange,
    centrisCode,
    updateCentrisCode,
    automatic_saisie,
    updateAutomaticSaisie,
    isCentrisCodeChange,
    qaciqCode,
    updateQaciqCode,
    isQaciqCodeChange,
    centrisCodeStatus,
    updateCentrisCodeStatus,
    isCentrisCodeStatusChange,
    fbPixelId,
    updateFBPixelId,
    isFBPixelIdChange,
    password,
    updatePassword,
    isPasswordChange,
    rePassword,
    updateRePassword,
    isRePasswordChange,
    isValidPassword,
    isValidRePassword,
    editStatus,
    updateEditStatus,
    onSave,
  } as IProfileContextProps;

  return <ProfileContext.Provider value={contextData}>{children}</ProfileContext.Provider>;
}

export default ProfileContextProvider;
