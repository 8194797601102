import _, { set } from 'lodash';

import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { Listing, FloorplanOrder, VirtualServiceOrder, Picture } from 'models';
import {
  fetchListings,
  fetchListing,
  fetchListingStats,
  fetchVirtualTourStats,
  postPhotoComment,
  deletePhotoComment,
  postGeneralComment,
  addVirtualServiceOrder,
  getVirtualServiceOrders,
  addFloorplanOrder,
  getFloorplanOrders,
  generateInvoiceForExtraService,
  brokerPurchasedPictures,
  brokerSwapPictures,
  setMLS,
  getListingRawPictures,
  fetchDescriptionGenerator,
  saveListingTags,
  saveEditedDescription,
  saveGeneratedDescriptionFeedback,
  fetchPreviousDescriptions,
  fetchGPTPromptTags,
  fetchMediaNotificationLogs,
  sendMediaEmailToClient,
  sendMediaSMSToClient,
  getListingVideoTemplates,
  orderListingVideo,
  requestCentrisUpload,
  addNewServiceOffer,
  updateFloorplanRoom,
} from 'apis';

const initialState = {
  data: [],
  pageInfo: {},
  stats: [],
  vrStats: [],
  virtualServiceOrders: [],
  floorplanOrders: [],
  addedVirtualServiceOrderStatus: '',
  addedFloorplanOrderStatus: '',
  addedInvoiceStatus: {},
  addedBrokerSwapPicturesStatus: '',
  addedPurchasedPicturesStatus: {},
  updateMLSStatus: '',
  isAuthorized: true,
  postPictureCommentStatus: '',
  deletePictureCommentStatus: '',
  rawPictures: [],
  listing_centris_data: {},
  editedText: '',
  generatedDescription: {},
  generatedDescriptionStatus: null,
  savedFeedbackStatus: null,
  savedEditedTextStatus: null,
  previousDescriptions: [],
  totalTokens: 0,
  nearbyPlaces: [],
  customPropertyTags: [],
  customFeatureTags: [],
  sentEmailStatus: null,
  sentEmailData: {},
  sentSMSStatus: null,
  sentSMSData: {},
  mediaNotificationLogs: [],
  videoTemplates: [],
  orderedListingVideoStatus: null,
  requestCentrisUploadStatus: null,
  addedServiceOfferStatus: null,
  uploadedRoomFloorTypeStatus: null,
};

export const listingsSlice = createSlice({
  name: 'listings',
  initialState,
  reducers: {
    initialize: (state) => {
      state.data = [];
      state.rawPictures = [];
      state.pageInfo = {};
      state.editedText = '';
      state.generatedDescription = {};
      state.generatedDescriptionStatus = null;
      state.savedFeedbackStatus = null;
      state.savedEditedTextStatus = null;
      state.previousDescriptions = [];
      state.totalTokens = 0;
      state.nearbyPlaces = [];
      state.customPropertyTags = [];
      state.customFeatureTags = [];
      state.listing_centris_data = {};
      state.sentEmailStatus = null;
      state.sentEmailData = {};
      state.sentSMSStatus = null;
      state.sentSMSData = {};
      state.mediaNotificationLogs = [];
      state.videoTemplates = [];
      state.orderedListingVideoStatus = null;
      state.requestCentrisUploadStatus = null;
      state.addedServiceOfferStatus = null;
      state.uploadedRoomFloorTypeStatus = null;
    },
    setPageInfo: (state, action) => {
      state.pageInfo = action.payload;
    },
    setListings: (state, action) => {
      state.data = action.payload;
    },
    updateListing: (state, action) => {
      const listing = action.payload;
      const index = _.findIndex(state.data, (m) => {
        return m.id === listing.id;
      });
      if (index !== -1) {
        const oldListing = _.cloneDeep(state.data[index]);
        Object.keys(listing).forEach((key) => {
          return listing[key] === undefined && delete listing[key];
        });
        _.merge(oldListing, listing);
        state.data[index] = oldListing;
      } else {
        state.data.push(listing);
      }
      state.addedBrokerSwapPicturesStatus = '';
      state.postPictureCommentStatus = '';
      state.updateMLSStatus = '';
      state.deletePictureCommentStatus = '';
      state.rawPictures = [];
    },
    addListingStats: (state, action) => {
      const { id, data } = action.payload;
      state.stats.push({ id, data });
      state.stats = _.uniqBy(state.stats, (m) => {
        return m.id;
      });
    },
    addVirtaulTourStats: (state, action) => {
      const { id, data } = action.payload;
      state.vrStats.push({ id, ...data });
      state.vrStats = _.uniqBy(state.vrStats, (m) => {
        return m.id;
      });
    },
    setVirtualServiceOrders: (state, action) => {
      state.virtualServiceOrders = action.payload;
      state.addedVirtualServiceOrderStatus = '';
    },
    setFloorplanOrders: (state, action) => {
      state.floorplanOrders = action.payload;
      state.addedFloorplanOrderStatus = '';
    },
    setAddedVirtualServiceOrderStatus: (state, action) => {
      state.addedVirtualServiceOrderStatus = action.payload;
    },
    setAddedFloorplanOrderStatus: (state, action) => {
      state.addedFloorplanOrderStatus = action.payload;
    },
    setAddedInvoiceStatus: (state, action) => {
      state.addedInvoiceStatus = action.payload;
    },
    setAddedPurchasedPicturesStatus: (state, action) => {
      state.addedPurchasedPicturesStatus = action.payload;
    },
    setAddedBrokerSwapPicturesStatus: (state, action) => {
      state.addedBrokerSwapPicturesStatus = action.payload;
    },
    setUpdateMLSStatus: (state, action) => {
      state.updateMLSStatus = action.payload;
    },
    setListingAuth: (state, action) => {
      state.isAuthorized = action.payload;
    },
    setPostPictureCommentStatus: (state, action) => {
      state.postPictureCommentStatus = action.payload;
    },
    setDeletePictureCommentStatus: (state, action) => {
      state.deletePictureCommentStatus = action.payload;
    },
    setRawPictures: (state, action) => {
      state.rawPictures = action.payload;
    },
    setListingCentrisData: (state, action) => {
      state.listing_centris_data = action.payload;
    },
    setGeneratedDescriptionData: (state, action) => {
      state.generatedDescription = action.payload;
    },
    setGeneratedDescriptionDataStatus: (state, action) => {
      state.generatedDescriptionStatus = action.payload;
    },
    setSavedFeedbackStatus: (state, action) => {
      state.savedFeedbackStatus = action.payload;
    },
    setEditedText: (state, action) => {
      state.editedText = action.payload;
    },
    setSavedEditedTextStatus: (state, action) => {
      state.savedEditedTextStatus = action.payload;
    },
    setPreviousDescriptions: (state, action) => {
      state.previousDescriptions = action.payload;
    },
    setTotalTokens: (state, action) => {
      state.totalTokens = action.payload;
    },
    setNearbyPlaces: (state, action) => {
      state.nearbyPlaces = action.payload;
    },
    setCustomPropertyTags: (state, action) => {
      state.customPropertyTags = action.payload;
    },
    setCustomFeatureTags: (state, action) => {
      state.customFeatureTags = action.payload;
    },
    setSentEmailStatus: (state, action) => {
      state.sentEmailStatus = action.payload;
    },
    setSentSMSStatus: (state, action) => {
      state.sentSMSStatus = action.payload;
    },
    setMediaNotificationLogs: (state, action) => {
      state.mediaNotificationLogs = action.payload;
    },
    setListingVideoTemplates: (state, action) => {
      state.videoTemplates = action.payload;
    },
    setOrderedListingVideoStatus: (state, action) => {
      state.orderedListingVideoStatus = action.payload;
    },
    setRequestCentrisUploadStatus: (state, action) => {
      state.requestCentrisUploadStatus = action.payload;
    },
    setAddedServiceOfferStatus: (state, action) => {
      state.addedServiceOfferStatus = action.payload;
    },
    setUploadedRoomFloorTypeStatus: (state, action) => {
      state.uploadedRoomFloorTypeStatus = action.payload;
    },
  },
});

// Actions
export const {
  initialize,
  setPageInfo,
  setListings,
  updateListing,
  addListingStats,
  addVirtaulTourStats,
  setVirtualServiceOrders,
  setListingAuth,
  setFloorplanOrders,
  setAddedVirtualServiceOrderStatus,
  setAddedFloorplanOrderStatus,
  setAddedInvoiceStatus,
  setAddedPurchasedPicturesStatus,
  setUpdateMLSStatus,
  setAddedBrokerSwapPicturesStatus,
  setPostPictureCommentStatus,
  setDeletePictureCommentStatus,
  setRawPictures,
  setSavedFeedbackStatus,
  setSavedEditedTextStatus,
  setGeneratedDescriptionDataStatus,
  setGeneratedDescriptionData,
  setEditedText,
  setPreviousDescriptions,
  setTotalTokens,
  setNearbyPlaces,
  setCustomPropertyTags,
  setCustomFeatureTags,
  setListingCentrisData,
  setSentEmailStatus,
  setSentSMSStatus,
  setMediaNotificationLogs,
  setListingVideoTemplates,
  setOrderedListingVideoStatus,
  setRequestCentrisUploadStatus,
  setAddedServiceOfferStatus,
  setUploadedRoomFloorTypeStatus,
} = listingsSlice.actions;

export const fetchListingsAsync = (token: string, page: number = 1, searchInput: string = '') => {
  return async (dispatch: Dispatch) => {
    const { pageInfo, listings } = await fetchListings(token, page, searchInput);
    if (listings && pageInfo) {
      dispatch(setPageInfo(pageInfo));
      dispatch(setListings(listings));
    }
  };
};

export const fetchListingAsync = (distToken: string, public_usage: boolean = false) => {
  return async (dispatch: Dispatch) => {
    const listing: Listing = await fetchListing(distToken, public_usage);
    if (listing) {
      dispatch(updateListing(listing));
    }
  };
};

export const fetchListingStatsAsync = (token: string, listingId: number) => {
  return async (dispatch: Dispatch) => {
    const res = await fetchListingStats(token, listingId);
    if (res) {
      dispatch(addListingStats({ id: listingId, data: res.data }));
    }
  };
};

export const fetchVirtualTourStatsAsync = (
  token: string,
  listingId: number,
  from: string,
  to: string,
) => {
  return async (dispatch: Dispatch) => {
    const data = await fetchVirtualTourStats(token, listingId, from, to);
    if (data) {
      dispatch(addVirtaulTourStats({ id: listingId, data }));
    }
  };
};
export const postPhotoCommentAsync = (
  token: string,
  distToken: string,
  photoId: number,
  comment: string,
  locationX: number,
  locationY: number,
  width: number,
  height: number,
  shape: string,
  shooting_id: number,
) => {
  return async (dispatch: Dispatch) => {
    const res = await postPhotoComment(
      token,
      photoId,
      comment,
      locationX,
      locationY,
      width,
      height,
      shape,
      shooting_id,
    );
    if (res !== null) {
      dispatch(setPostPictureCommentStatus(res.status));
    } else {
      dispatch(setPostPictureCommentStatus(500));
    }
    const listing: Listing = await fetchListing(distToken);
    if (listing) {
      dispatch(updateListing(listing));
    }
  };
};

export const deletePhotoCommentAsync = (token: string, ticket_id: number, distToken: string) => {
  return async (dispatch: Dispatch) => {
    const response = await deletePhotoComment(token, ticket_id);
    if (response !== null) {
      dispatch(setDeletePictureCommentStatus(response.status));
    } else {
      dispatch(setDeletePictureCommentStatus(500));
    }
    const listing: Listing = await fetchListing(distToken);
    if (listing) {
      dispatch(updateListing(listing));
    }
  };
};

export const postGeneralCommentAsync = (token: string, comment: string, listingId: number) => {
  return async (dispatch: Dispatch) => {
    await postGeneralComment(token, comment, listingId);
  };
};

export const addVirtualServiceOrderAsync = (
  token: string,
  virtualServiceOrder: VirtualServiceOrder,
  broker_id: string,
) => {
  return async (dispatch: Dispatch) => {
    const res = await addVirtualServiceOrder(token, virtualServiceOrder, broker_id);
    if (res != null) {
      dispatch(setAddedVirtualServiceOrderStatus(res.data.status));
    } else {
      dispatch(
        setAddedVirtualServiceOrderStatus({
          status: 500,
          message: 'Something went wrong with the invoice creation, order as been cancelled',
        }),
      );
    }
  };
};

export const createInvoiceForAdditionalServiceAsync = (
  token: string,
  listing: Listing,
  selectedPictures: Array<any>,
  service: string,
) => {
  return async (dispatch: Dispatch) => {
    const res = await generateInvoiceForExtraService(token, listing, selectedPictures, service);
    if (res != null) {
      dispatch(setAddedInvoiceStatus(res.data));
    } else {
      dispatch(
        setAddedInvoiceStatus({
          status: 500,
          message: 'Something went wrong with the invoice creation',
        }),
      );
    }
  };
};
export const brokerPurchasedPicturesAsync = (
  token: string,
  invoice_id: any,
  pictureIds: Array<number>,
  uneditedPictures: boolean,
) => {
  return async (dispatch: Dispatch) => {
    const res = await brokerPurchasedPictures(token, invoice_id, pictureIds, uneditedPictures);
    dispatch(setAddedPurchasedPicturesStatus(res.data));
  };
};

export const brokerSwapPicturesAsync = (
  token: string,
  picturesToExchangeWith: Array<number>,
  picturesToExchange: Array<number>,
) => {
  return async (dispatch: Dispatch) => {
    const res = await brokerSwapPictures(token, picturesToExchangeWith, picturesToExchange);
    dispatch(setAddedBrokerSwapPicturesStatus(res.data.status));
  };
};

export const setMLSAsync = (token: string, listing_id: number, mls: string) => {
  return async (dispatch: Dispatch) => {
    const res = await setMLS(token, listing_id, mls);
    if (res !== null) {
      dispatch(setUpdateMLSStatus(res.status));
    } else {
      dispatch(setUpdateMLSStatus(500));
    }
  };
};

export const getVirtualServiceOrdersAsync = (
  token: string,
  listing_id: number,
  broker_id: string,
) => {
  return async (dispatch: Dispatch) => {
    const res = await getVirtualServiceOrders(token, listing_id, broker_id);
    if (res) {
      dispatch(setVirtualServiceOrders(res.data));
    } else {
      dispatch(setListingAuth(false));
    }
  };
};

export const addFloorplanOrderAsync = (
  token: string,
  floorplanOrder: FloorplanOrder,
  broker_id: string,
  actor: string,
  action: string,
) => {
  return async (dispatch: Dispatch) => {
    const res = await addFloorplanOrder(token, floorplanOrder, broker_id, actor, action);
    if (res != null) {
      dispatch(setAddedFloorplanOrderStatus(res.data.status));
    } else {
      dispatch(
        setAddedFloorplanOrderStatus({
          status: 500,
          message: 'Something went wrong with the invoice creation, order as been cancelled',
        }),
      );
    }
  };
};

export const getFloorplanOrdersAsync = (token: string, listing_id: number, broker_id: string) => {
  return async (dispatch: Dispatch) => {
    const res = await getFloorplanOrders(token, listing_id, broker_id);
    if (res) {
      dispatch(setFloorplanOrders(res.data));
    }
  };
};

export function getListingRawPicturesAsync(shooting_id: number, listing_id: number, token: string) {
  return async (dispatch: Dispatch) => {
    const pictures = await getListingRawPictures(shooting_id, listing_id, token);
    if (pictures) {
      dispatch(setRawPictures(pictures));
    }
  };
}

export function saveDescriptionAsync(
  token: string,
  gpt_usage_id: number,
  editedDescription: string,
) {
  return async (dispatch: Dispatch) => {
    const res = await saveEditedDescription(token, gpt_usage_id, editedDescription);
    if (res && res.status === 200) {
      setSavedEditedTextStatus(res.status);
      dispatch(setEditedText(res.data.data.description));
    } else {
      dispatch(setSavedEditedTextStatus(500));
    }
  };
}

export function saveListingTagsAsync(token: string, listing_id: number, prompt: any) {
  return async (dispatch: Dispatch) => {
    await saveListingTags(token, listing_id, prompt);
  };
}
export function saveFeedbackAsync(token: string, gpt_usage_id: number, feedback: number) {
  return async (dispatch: Dispatch) => {
    const res = await saveGeneratedDescriptionFeedback(token, gpt_usage_id, feedback);
    if (res && res.status === 200) {
      dispatch(setSavedFeedbackStatus(res.status));
    } else {
      dispatch(setSavedFeedbackStatus(500));
    }
  };
}
export function fetchDescriptionGeneratorAsync(
  token: string,
  broker_id: number,
  listing_id: number,
  prompt: any,
  textLength: string,
  language: string,
) {
  return async (dispatch: Dispatch) => {
    const res = await fetchDescriptionGenerator(
      token,
      broker_id,
      listing_id,
      prompt,
      textLength,
      language,
    );
    if (res && res.data && res.status === 200) {
      dispatch(setGeneratedDescriptionDataStatus(res.status));
      dispatch(setGeneratedDescriptionData(res.data));
    } else {
      dispatch(setGeneratedDescriptionDataStatus(500));
      dispatch(setGeneratedDescriptionData(null));
    }
  };
}

export function fetchPreviousDescriptionsAsync(
  token: string,
  broker_id: number,
  listing_id: number,
) {
  return async (dispatch: Dispatch) => {
    const res = await fetchPreviousDescriptions(token, broker_id, listing_id);
    const { total_tokens, descriptions } = res;
    if (descriptions) {
      const sortedDescriptions = descriptions.sort((a: any, b: any) => {
        return b.gpt_usage_id - a.gpt_usage_id;
      });
      dispatch(setPreviousDescriptions(sortedDescriptions));
    }
    if (total_tokens !== null) {
      dispatch(setTotalTokens(total_tokens));
    }
  };
}

export function fetchGPTPromptTagsAsync(
  token: string,
  listing_id: number,
  lat: number,
  lng: number,
  language: string,
) {
  return async (dispatch: Dispatch) => {
    const res = await fetchGPTPromptTags(token, listing_id, lat, lng, language);
    const { nearby_places, property_tags, including_features } = res;
    let nearbyPlaces = nearby_places.map((place: any) => {
      return place.name;
    });
    nearbyPlaces = _.uniq(nearbyPlaces);
    if (nearby_places && nearby_places !== null) {
      dispatch(setNearbyPlaces(nearbyPlaces));
    }
    if (property_tags && property_tags !== null) {
      dispatch(setCustomPropertyTags(property_tags));
    }
    if (including_features && including_features !== null) {
      dispatch(setCustomFeatureTags(including_features));
    }
  };
}

export function fetchMediaNotificationAsync(token: string, listing_id: number, broker_id: number) {
  return async (dispatch: Dispatch) => {
    const res = await fetchMediaNotificationLogs(token, listing_id, broker_id);
    if (res && res.status === 200) {
      const sortedData = res.data.sort((a: any, b: any) => {
        return Date.parse(b.timestamp) - Date.parse(a.timestamp);
      });
      dispatch(setMediaNotificationLogs(sortedData));
    }
  };
}
export function sendMediaEmailToClientAsync(
  token: string,
  client_firstname: string,
  client_lastname: string,
  email: string,
  client_lang: string,
  listing_id: number,
  broker_id: number,
  public_delivery_url: string,
) {
  return async (dispatch: Dispatch) => {
    const res = await sendMediaEmailToClient(
      token,
      client_firstname,
      client_lastname,
      email,
      client_lang,
      listing_id,
      broker_id,
      public_delivery_url,
    );
    if (res && res.status === 200) {
      dispatch(setSentEmailStatus(res.status));
    }
    dispatch(setSentEmailStatus(null));
  };
}

export function sendMediaSMSToClientAsync(
  token: string,
  client_firstname: string,
  client_lastname: string,
  phone_number: string,
  client_lang: string,
  listing_id: number,
  broker_id: number,
  public_delivery_url: string,
) {
  return async (dispatch: Dispatch) => {
    const res = await sendMediaSMSToClient(
      token,
      client_firstname,
      client_lastname,
      phone_number,
      client_lang,
      listing_id,
      broker_id,
      public_delivery_url,
    );
    if (res && res.status === 200) {
      dispatch(setSentSMSStatus(res.status));
    }
    dispatch(setSentSMSStatus(null));
  };
}

export const fetchListingVideoTemplatesAsync = (token: string) => {
  return async (dispatch: Dispatch) => {
    const res = await getListingVideoTemplates(token);
    if (res) {
      dispatch(setListingVideoTemplates(res.data));
    } else {
      dispatch(setListingAuth(false));
    }
  };
};

export const orderListingVideoAsync = (
  token: string,
  listing_id: number,
  template_id: number,
  pictures: Picture[],
) => {
  return async (dispatch: Dispatch) => {
    const res = await orderListingVideo(token, listing_id, template_id, pictures);
    if (res) {
      dispatch(setOrderedListingVideoStatus(res.status));
    } else {
      dispatch(setOrderedListingVideoStatus(500));
    }
  };
};

export const orderCentrisUploadAsync = (
  token: string,
  broker_id: number,
  listing_id: number,
  shooting_id: number,
) => {
  return async (dispatch: Dispatch) => {
    const res = await requestCentrisUpload(token, broker_id, listing_id, shooting_id);
    if (res) {
      dispatch(setRequestCentrisUploadStatus(res.status));
    } else {
      dispatch(setRequestCentrisUploadStatus(500));
    }
  };
};

export const updateFloorplanRoomAsync = (token: string, room_id: number, floor_type: string) => {
  return async (dispatch: Dispatch) => {
    const res = await updateFloorplanRoom(token, room_id, floor_type);
    if (res) {
      dispatch(setUploadedRoomFloorTypeStatus(res.status));
    } else {
      dispatch(setUploadedRoomFloorTypeStatus(500));
    }
    dispatch(setUploadedRoomFloorTypeStatus(null));
  };
};

export const addNewServiceOfferAsync = (
  token: string,
  distToken: string,
  shooting_id: number,
  service_id: number,
) => {
  return async (dispatch: Dispatch) => {
    const res = await addNewServiceOffer(token, shooting_id, service_id);
    if (res && res.status === 200) {
      dispatch(setAddedServiceOfferStatus(200));
      const listing: Listing = await fetchListing(distToken);
      if (listing) {
        dispatch(updateListing(listing));
      }
    } else {
      dispatch(setAddedServiceOfferStatus(500));
    }
    dispatch(setAddedServiceOfferStatus(null));
  };
};

export default listingsSlice.reducer;
