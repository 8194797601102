const IS_DEV = window.location.href.indexOf('localhost') > -1;
export const CURRENT_APP_VERSION = 512;
export const GOOGLE_MAPS_API_KEY = 'AIzaSyCBlV8_octv2fJn5m6dAPJPn0UozRFm_q8';

export const FR = 'fr';
export const EN = 'en';
export const ENG = 'en-US';
export const LOCALES = [ENG, FR];
export const ENGLISH_LOCALES = ['en', 'eng', 'en-US'];

export const FRENCH = 'FR';
export const ENGLISH = 'EN';

export const KEYS = {
  USER: 'user',
  TOKEN: 'token',
  LANGUAGE: 'language',
  SHOOTINGDATE: 'shootingDate',
  PORTAL_TIMER: 'portal_timer',
  COOKIES_CONSENT: 'cookies_consent',
};

export const PICTURE_SERVICE_OFFER = {
  applicationTraditionalPic: 180,
  applicationBlueHourPic: 179,
  applicationDronePic: 181,
  plateformeTraditionalPic: 176,
  plateformeBlueHourPic: 178,
  plateformeDronePic: 177,
  carteTraditionalPic: 245,
  carteBlueHourPic: 243,
  carteDronePic: 244,
};

export const PICTURE_RESOLUTION_TYPE = {
  original: 0,
  thumbnail: 1,
  distrib: 2,
  watermark: 3,
  raw: 4,
  raw_thumbnail: 5,
};

export const MEDIA_TYPES = {
  JPG: 1,
  DRONE_JPG: 2,
  INSTA360: 3,
  DRONE_VIDEO: 4,
  VIDEO: 5,
  ARW: 6,
  DRONE_DNG: 7,
};

export const SERVICE_OFFER_CATEGORY_NEEDING_UPLOAD = [6, 3];

export const SERVICE_OFFER_TYPE = {
  HDR: 'hdr',
};

export const SERVICE_OFFER_CATEGORY = {
  FLOORPLAN: 6,
  VIDEO: 3,
  MATTERPORT: 1,
  SIGNATURE: 7,
};

export const SERVICE_OFFER_CATEGORY_ICON = {
  SAME_DAY_DELIVERY: 'same_day_delivery',
  SIGNATURE: 'signature',
  SHOOTING_PACKAGE: 'shooting_package',
  CARTE: 'carte',
  SEASONAL: 'seasonal',
  DRONE: 'drone',
  PICTURE_UPLOAD: 'Picture Upload',
};

export const SERVICE_OFFER_CATEGORY_NAME = {
  FLOOR_PLAN: 'Floorplan',
};

export const CARTE_SERVICE_OFFERS = {
  MEASURE: 343, // TODO: Change this to the correct service offer id
};

export const MATTERPORT_FLOORPLAN_SERVICE_OFFER_ID = [220, 166, 165, 164];

export const SUBSCRIPTION = {
  base: 1,
  application: 9,
  plateforme: 8,
  carte: 11,
};

export const ZOHO_REFERRAL_CODE_API_NAME = 'cf_referral_code';

export const CONTACT_EMAIL_ADDRESS = 'contact@hausvalet.ca';
export const CONTACT_PHONE_NUMBER = '1-844-505-4287';
export const CONTACT_SOCIAL_LINKS = {
  FACEBOOK: 'https://www.facebook.com/hausvalet/',
  YOUTUBE: 'https://www.youtube.com/channel/UCyDMwFhiVC99fUmBn5_-WAA',
  LINKEDIN: 'https://www.linkedin.com/company/hausvalet/',
  INSTAGRAM: 'https://www.instagram.com/hausvalet.ig/',
};
export const PICTURE_UPLOAD_IDS = [85, 95, 7, 33, 44, 57, 61];
function getServiceOfferIds() {
  if (IS_DEV) {
    return {
      shooting_package: [999],
      virtual_tour: [998],
      drone: [9, 19, 161],
      virtual_staging: [10, 44, 39],
      floorplan: [26, 48, 49, 50, 51],
      blue_hour: [27, 42, 38],
      video: [17, 24],
      matterport: [23],
    };
  }

  return {
    shooting_package: [999],
    virtual_tour: [998],
    drone: [
      151, 152, 159, 126, 131, 157, 130, 133, 158, 175, 174, 173, 156, 124, 128, 153, 160, 150, 30,
      88, 98, 162, 163, 161, 170, 172, 171, 169, 167, 168,
    ],
    virtual_staging: [129, 123, 92, 73, 82, 155],
    floorplan: [
      151, 152, 159, 126, 131, 157, 130, 133, 158, 175, 174, 173, 156, 124, 128, 153, 160, 150, 200,
      192, 184, 188, 204, 196, 193, 185, 201, 197, 189, 205, 183, 191, 199, 195, 203, 187, 198, 190,
      182, 202, 186, 194, 104, 102, 108, 103, 101, 107,
    ],
    blue_hour: [
      151, 152, 159, 126, 131, 157, 130, 133, 158, 156, 124, 128, 153, 160, 150, 29, 87, 97, 136,
      122, 154,
    ],
    video: [
      151, 152, 159, 126, 131, 157, 130, 133, 158, 175, 174, 173, 156, 124, 128, 153, 160, 150, 83,
      98, 41, 99, 41, 89, 28, 96, 86, 120, 116, 115,
    ],
    matterport: [151, 152, 159, 34, 94, 84],
  };
}

function getBrokerServiceOffers() {
  return [
    { key: 'more_leads' },
    { key: 'mobile_app' },
    { key: 'photography' },
    { key: 'floorplan' },
    { key: 'video' },
    { key: 'virtual_tour' },
    { key: 'drone' },
    { key: 'blue_hour' },
    { key: 'ai_virtual_staging' },
    {
      key: 'virtual_staging',
    },
    { key: 'virtual_renovation' },
    { key: 'matterport' },
    { key: 'broker_website' },
  ];
}
function getClientServiceOffers() {
  return [
    { key: 'more_offers' },
    {
      key: 'qualified_leads',
    },
    { key: 'photography' },
    { key: 'floorplan' },
    { key: 'video' },
    { key: 'virtual_tour' },
    { key: 'drone' },
    { key: 'blue_hour' },
    { key: 'ai_virtual_staging' },
    {
      key: 'virtual_staging',
    },
    { key: 'virtual_renovation' },
    { key: 'matterport' },
  ];
}
export const REDIRECTED_HOSTNAME = [
  'media.hausvalet.ca',
  'portal.hausvalet.ca',
  'portail.hausvalet.ca',
  'photo.hausvalet.ca',
  'photos.hausvalet.ca',
  'picture.hausvalet.ca',
  'pictures.hausvalet.ca',
  'admin.hausvalet.ca',
  'facturation.hausvalet.ca',
  'visit.hausvalet.ca',
  'visite.hausvalet.ca',
  'devviste.hausvalet.ca',
  'devvisit.hausvalet.ca',
  'stagingvisit.hausvalet.ca',
  'stagingvisite.hausvalet.ca',
  'devadmin.hausvalet.ca',
  'stagingadmin.hausvalet.ca',
  'livraison.hausvalet.ca',
  'delivery.hausvalet.ca',
  'devportail.hausvalet.ca',
  'devportal.hausvalet.ca',
  'viewer.hausvalet.ca',
  'oldviewer.hausvalet.ca',
  'redirect.hausvalet.ca',
  'zohoadmin.hausvalet.ca',
  'stagingportail.hausvalet.ca',
  'stagingportal.hausvalet.ca',
  '34.152.60.103',
  '34.152.10.110',
  '35.234.255.120',
  'localhost',
];

export const SERVICE_OFFERS_PUBLIC_PAGE = getServiceOfferIds();
export const BROKER_SERVICE_OFFERS_REQUEST_PAGE = getBrokerServiceOffers();
export const CLIENT_SERVICE_OFFERS_REQUEST_PAGE = getClientServiceOffers();
export const MINIMUM_AMOUNT_OF_DISPLAYED_LISTINGS = 100;
export const MAX_ROOMS = 99;
export const HOUSE_MIN_ROOMS = 15;
export const CONDO_MIN_ROOMS = 10;

export const SQUARE_FOOTAGE_MIN = 0;
export const SQUARE_FOOTAGE_MAX = 10000;
export const SQUARE_FOOTAGE_STEP = 100;

export const DISTANCE_LIMIT = 160000;

export const BROKER_PUBLIC_DOMAIN_SUFFIX = '.hausvalet.ca';

export const CAPTCHA_KEY = '6Le9Pw4lAAAAAKaRqtcfeKZHtn92Oj_xEYy76P8r';

export const DOMAIN_WAITING_TIME = 45000;

export const ESOFT_TECH_ID = 1985;

export const HAUSVALET_FB_PAGE_ID = '397738740953831';
export const HAUSVALET_FB_PIXEL_ID = '297900481801147';

export const FB_MESSENGER_EXPLANATION_VIDEO_URL = 'https://www.youtube.com';
export const PIXEL_ID_EXPLANATION_VIDEO_URL = 'https://www.youtube.com';
export const LEAD_TYPES = {
  public_portal: 2,
};

export const MARKER_STATE = {
  created: 0,
  validated: 1,
  rejected: 2,
  fixStitch: 3,
  fixHdr: 4,
  fixPan: 5,
  fixManual: 6,
  processed: 7,
  enqueued: 100,
};

export const SHOOTING_STATE = {
  created: {
    KEY: 0,
    VALUE: 'created',
  },
  captured: {
    KEY: 1,
    VALUE: 'captured',
  },
  validated: {
    KEY: 2,
    VALUE: 'validated',
  },
  retouch: {
    KEY: 3,
    VALUE: 'retouch',
  },
  processed: {
    KEY: 4,
    VALUE: 'processed',
  },
  uploaded: {
    KEY: 5,
    VALUE: 'uploaded',
  },
  confirmed: {
    KEY: 6,
    VALUE: 'confirmed',
  },
  inprogress: {
    KEY: 8,
    VALUE: 'inprogress',
  },
  completed: {
    KEY: 9,
    VALUE: 'completed',
  },
  canceled: {
    KEY: 10,
    VALUE: 'canceled',
  },
  recommended: {
    KEY: 12,
    VALUE: 'recommended',
  },
  abandonned: {
    KEY: 13,
    VALUE: 'abandonned',
  },
  delivered: {
    KEY: 14,
    VALUE: 'delivered',
  },
};

export const VIDEO_STATUS = {
  requested: 0,
  enqueued: 1,
  success: 2,
  failure: 3,
};

export const DISTANCE_CHARGE_PARAMETERS = {
  kmMinCost: 0.5,
  kmMaxCost: 0.59,
  baseKms: 50,
  kmCostException: [1016, 1017],
};

export const GOOGLE_MAP_LOCATION_CONSTANTS = {
  office: '45.50640,-73.43888',
  prefix: 'https://www.google.com/maps/dir',
};

export const COOKIES_OPTIONS_KEYS = {
  ESSENTIAL: 'essential',
  FUNCTIONAL: 'functional',
  PERFORMANCE: 'performance',
};
