import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { getAllJobs } from 'apis';

const initialState = {
  jobs: [],
};

export const bullJobsSlices = createSlice({
  name: 'bullJobs',
  initialState,
  reducers: {
    initialize: (state) => {
      state.jobs = [];
    },
    setJobs: (state, action) => {
      state.jobs = action.payload;
    },
  },
});

// Actions
export const { initialize, setJobs } = bullJobsSlices.actions;

export const fetchAlljobsAsync = (token: string) => {
  return async (dispatch: Dispatch) => {
    const allJobs = await getAllJobs(token);
    if (allJobs) {
      if (allJobs.length === 0) {
        dispatch(setJobs([]));
      }
      dispatch(setJobs(allJobs));
    }
  };
};

export default bullJobsSlices.reducer;
