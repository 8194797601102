/* eslint-disable import/no-extraneous-dependencies */
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { reactLocalStorage } from 'reactjs-localstorage';
import { User } from 'models';
import {
  initApp,
  initListings,
  initShootings,
  setUser,
  setLanguage,
  showAlert,
  hideAlert,
  setSubscriptionPlans,
} from 'state/actions';
import { LOADING } from 'state/constants';
import { Loader } from '@googlemaps/js-api-loader';
import { fetchSubscriptionPlans } from 'apis';
import { KEYS, LOCALES, FR, PLANS, GOOGLE_MAPS_API_KEY } from 'config/constants';
import { IMenuItem, IPlanListItem, IPlanItem } from 'types';
import { useEffect, useState } from 'react';

export const useInitState = () => {
  const dispatch = useDispatch();

  function initState() {
    dispatch(initApp());
    dispatch(initListings());
    dispatch(initShootings());
  }

  return { initState };
};

export const useAuth = () => {
  const dispatch = useDispatch();

  let { user, token } = useSelector((state: any) => {
    return state.app;
  });

  if (!user || !token) {
    const _token = reactLocalStorage.get(KEYS.TOKEN);
    const _user = reactLocalStorage.getObject(KEYS.USER);
    if (_user && _token) {
      token = _token;
      user = new User(_user);

      dispatch(setUser({ user, token }));
    }
  }
  const isAdmin = user?.isAdmin;
  const isLoggedIn = user && token;

  return { user, token, isAdmin, isLoggedIn };
};

export const useLanguage = () => {
  const dispatch = useDispatch();

  let { language } = useSelector((state: any) => {
    return state.app;
  });

  if (!language) {
    language = reactLocalStorage.get(KEYS.LANGUAGE);

    if (!language) {
      language = navigator.languages && navigator.languages[0];
      language = language || navigator.language || navigator.userLanguage;

      if (!LOCALES.includes(String(language))) {
        language = FR;
      }
    } else {
      dispatch(setLanguage(language));
    }
  }

  return language;
};

let timerId: any = null;
export const useAlert = () => {
  const dispatch = useDispatch();

  const displayAlert = (status: string, message: string) => {
    if (timerId) {
      clearTimeout(timerId);
    }

    dispatch(showAlert({ status, message }));

    timerId = setTimeout(() => {
      timerId = null;
      dispatch(hideAlert());
    }, 5000);
  };

  return { displayAlert };
};

const useAdminMenuItems = () => {
  const intl = useIntl();

  return [
    {
      link: '/bill-creation-freelancer',
      text: intl.formatMessage({ id: 'freelanceBilling.mainTitle' }),
      type: intl.formatMessage({ id: 'menuTypes.adminTools' }),
    },
    {
      link: '/promotion-manager',
      text: intl.formatMessage({ id: 'promotion.mainTitle' }),
      type: intl.formatMessage({ id: 'menuTypes.adminTools' }),
    },
    {
      link: '/feedback-manager',
      text: intl.formatMessage({ id: 'feedback.title' }),
      type: intl.formatMessage({ id: 'menuTypes.adminTools' }),
    },
    {
      link: '/custom-shooting-offer',
      text: intl.formatMessage({ id: 'customShootingOffer.title' }),
      type: intl.formatMessage({ id: 'menuTypes.adminTools' }),
    },
  ];

  // return [
  //   {
  //     text: intl.formatMessage({ id: 'listings' }),
  //     links: [
  //       { link: '/media-directory', text: intl.formatMessage({ id: 'allListings' }) },
  //       { link: '/mls-match', text: intl.formatMessage({ id: 'mlsMatch' }) },
  //       { link: '/deliveries', text: intl.formatMessage({ id: 'deliveries' }) },
  //     ],
  //   },
  //   {
  //     text: intl.formatMessage({ id: 'shootings' }),
  //     links: [
  //       { link: '/dashboard', text: intl.formatMessage({ id: 'dashboard' }) },
  //       { link: '/calendar', text: intl.formatMessage({ id: 'calendar' }) },
  //       { link: '/scheduler', text: intl.formatMessage({ id: 'scheduler' }) },
  //       { link: '/injector', text: intl.formatMessage({ id: 'injector' }) },
  //       { link: '/review', text: intl.formatMessage({ id: 'review' }) },
  //     ],
  //   },
  //   {
  //     text: intl.formatMessage({ id: 'users' }),
  //     links: [
  //       { link: '/all-users', text: intl.formatMessage({ id: 'allUsers' }) },
  //       { link: '/photographers', text: intl.formatMessage({ id: 'photographers' }) },
  //       { link: '/brokers', text: intl.formatMessage({ id: 'brokers' }) },
  //       { link: '/post-production', text: intl.formatMessage({ id: 'postProduction' }) },
  //     ],
  //   },
  //   {
  //     text: intl.formatMessage({ id: 'servicesZones' }),
  //     links: [
  //       { link: '/zones', text: intl.formatMessage({ id: 'zones' }) },
  //       { link: '/services', text: intl.formatMessage({ id: 'services' }) },
  //       { link: '/depots', text: intl.formatMessage({ id: 'depots' }) },
  //       { link: '/packages', text: intl.formatMessage({ id: 'packages' }) },
  //     ],
  //   },
  //   {
  //     text: intl.formatMessage({ id: 'dev' }),
  //     links: [
  //       { link: '/api-logs', text: intl.formatMessage({ id: 'apiLogs' }) },
  //       { link: '/hmd', text: intl.formatMessage({ id: 'hmd' }) },
  //       { link: '/releases', text: intl.formatMessage({ id: 'releases' }) },
  //       { link: '/automated-videos', text: intl.formatMessage({ id: 'automatedVideos' }) },
  //     ],
  //   },
  // ];

  // return [];
};

const useTechnMenuItems = () => {
  const intl = useIntl();
  return [
    {
      link: '/remote-injector',
      text: intl.formatMessage({ id: 'injector' }),
      type: intl.formatMessage({ id: 'menuTypes.prodTechTools' }),
    },
    {
      link: '/prod-shootings',
      text: intl.formatMessage({ id: 'listings' }),
      type: intl.formatMessage({ id: 'menuTypes.prodTechTools' }),
    },
    {
      link: '/prod-dispatcher',
      text: intl.formatMessage({ id: 'shootingPlanner' }),
      type: intl.formatMessage({ id: 'menuTypes.prodTechTools' }),
    },
    {
      link: '/prod-scheduler',
      text: intl.formatMessage({ id: 'prodScheduler' }),
      type: intl.formatMessage({ id: 'menuTypes.prodTechTools' }),
    },
  ];
};

const usePostProdMenuItems = () => {
  const intl = useIntl();
  return [
    // { link: '/post-prod-dispatcher', text: intl.formatMessage({ id: 'postProd.menu' }) },
    {
      link: '/post-prod-dashboard',
      text: intl.formatMessage({ id: 'postProd.dashboardMenu' }),
      type: intl.formatMessage({ id: 'menuTypes.postProduction' }),
    },
    {
      link: '/jobs-dashboard',
      text: intl.formatMessage({ id: 'jobsDashboard.menu' }),
      type: intl.formatMessage({ id: 'menuTypes.postProduction' }),
    },
    // {
    //   link: '/esoft-dashboard',
    //   text: intl.formatMessage({ id: 'postProd.esoftDashboardMenu' }),
    //   type: intl.formatMessage({ id: 'menuTypes.postProduction' }),
    // },
    {
      link: '/imagen-dashboard',
      text: intl.formatMessage({ id: 'postProd.imagenDashboardMenu' }),
      type: intl.formatMessage({ id: 'menuTypes.postProduction' }),
    },
  ];
};

const useBrokerMenuItems = () => {
  const intl = useIntl();
  const { user } = useAuth();
  const routes = [
    {
      link: '/shootings',
      text: intl.formatMessage({ id: 'shootings' }),
      type: intl.formatMessage({ id: 'menuTypes.BrokerTools' }),
    },
    {
      link: '/media-directory',
      text: intl.formatMessage({ id: 'listings' }),
      type: intl.formatMessage({ id: 'menuTypes.BrokerTools' }),
    },
  ];
  routes.push({
    link: '/edit',
    text: intl.formatMessage({ id: 'personalPage' }),
    type: intl.formatMessage({ id: 'menuTypes.BrokerTools' }),
  });
  return routes;
};
export const useSupportItems = () => {
  const intl = useIntl();
  const routes = [
    {
      link: '/support',
      text: intl.formatMessage({ id: 'menuTypes.support' }),
      type: intl.formatMessage({ id: 'menuTypes.support' }),
    },
  ];
  return routes;
};
export const useMenuItems = (user: User) => {
  const location = useLocation();

  if (user == null) {
    return [];
  }

  let menuItems = [];
  const supportItems = useSupportItems();
  if (user.isAdmin) {
    const adminItems = useAdminMenuItems();
    const postProdItems = usePostProdMenuItems();
    const brokerItems = useBrokerMenuItems();
    const technItems = useTechnMenuItems();
    menuItems = [...menuItems, ...adminItems, ...postProdItems, ...brokerItems, ...technItems];
  }

  if (user.isTechn) {
    const technItems = useTechnMenuItems();
    menuItems = [...menuItems, ...technItems];
  }

  if (user.isPostProd) {
    const postProdItems = usePostProdMenuItems();
    menuItems = [...menuItems, ...postProdItems];
  }

  if (user.isBroker) {
    const brokerItems = useBrokerMenuItems();
    menuItems = [...menuItems, ...brokerItems];
    menuItems = menuItems.filter((m: IMenuItem) => {
      return !m.link.includes(location.pathname);
    });
  }
  menuItems = [...menuItems, ...supportItems];
  return menuItems;
  /* return menuItems.filter((m: IMenuItem) => {
    return !m.link.includes(location.pathname);
  }); */
};
export const useSubscriptionPlans = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(LOADING.NONE);
  const { subscription_plans } = useSelector((state: any) => {
    return state.app;
  });
  const getSubscriptionPlans = async () => {
    setLoading(LOADING.STARTED);
    const res = await fetchSubscriptionPlans();
    if (res && res.status === 200) {
      dispatch(setSubscriptionPlans(res.data.subscriptions));
      setLoading(LOADING.FINISHED);
    }
  };
  useEffect(() => {
    getSubscriptionPlans();
  }, []);
  return { subscription_plans, loading };
};
export const usePlanItems = () => {
  const intl = useIntl();
  const planItems: IPlanItem[] = [
    {
      title: intl.formatMessage({ id: 'plan.free.virtualTour' }),
      enabled: [PLANS.FREE],
      comingFeature: false,
    },
    {
      title: intl.formatMessage({ id: 'plan.free.mobileWeb' }),
      enabled: [PLANS.FREE],
      comingFeature: false,
    },
    {
      title: intl.formatMessage({ id: 'plan.free.instantAppointment' }),
      enabled: [PLANS.FREE],
      comingFeature: false,
    },
    {
      title: intl.formatMessage({ id: 'plan.free.staging' }),
      enabled: [PLANS.FREE],
      comingFeature: false,
    },
    {
      title: intl.formatMessage({ id: 'plan.free.roomsMeasures' }),
      enabled: [PLANS.FREE],
      comingFeature: false,
    },
    {
      title: intl.formatMessage({ id: 'plan.free.flooringTypes' }),
      enabled: [PLANS.FREE],
      comingFeature: false,
    },
    {
      title: intl.formatMessage({ id: 'plan.monthly.discountedPrices' }),
      enabled: [PLANS.MONTHLY, PLANS.YEARLY],
      comingFeature: false,
    },
    {
      title: intl.formatMessage({ id: 'plan.monthly.nextDelivery' }),
      enabled: [PLANS.MONTHLY, PLANS.YEARLY],
      comingFeature: false,
    },
    {
      title: intl.formatMessage({ id: 'plan.monthly.personalPost' }),
      enabled: [PLANS.MONTHLY, PLANS.YEARLY],
      comingFeature: false,
    },
    {
      title: intl.formatMessage({ id: 'plan.monthly.centrisUpload' }),
      enabled: [PLANS.MONTHLY, PLANS.YEARLY],
      comingFeature: false,
    },
    {
      title: intl.formatMessage({ id: 'plan.monthly.vTourStatistiques' }),
      enabled: [PLANS.MONTHLY, PLANS.YEARLY],
      comingFeature: false,
    },
    {
      title: intl.formatMessage({ id: 'plan.monthly.fbPixel' }),
      enabled: [PLANS.MONTHLY, PLANS.YEARLY],
      comingFeature: false,
    },
    {
      title: intl.formatMessage({ id: 'plan.monthly.ggTagManager' }),
      enabled: [PLANS.MONTHLY, PLANS.YEARLY],
      comingFeature: true,
    },
    {
      title: intl.formatMessage({ id: 'plan.monthly.user' }),
      enabled: [PLANS.MONTHLY, PLANS.YEARLY],
      comingFeature: false,
    },
  ];

  const planList: IPlanListItem[] = [
    {
      type: PLANS.FREE,
      title: '',
      price: intl.formatMessage({ id: 'plan.free' }),
    },
    {
      type: PLANS.MONTHLY,
      title: intl.formatMessage({ id: 'plan.monthly' }),
      price: '$95',
    },
    {
      type: PLANS.YEARLY,
      title: intl.formatMessage({ id: 'plan.yearly' }),
      price: '$950',
    },
  ];

  const result = {
    planItems,
    planList,
  };

  return result;
};

export const useGoogleAPIServices = () => {
  const [isGoogleAPILoaded, setGoogleAPILoaded] = useState(false);
  useEffect(() => {
    const loadGoogleMapsApi = () => {
      const loader = new Loader({
        apiKey: GOOGLE_MAPS_API_KEY,
        version: 'weekly',
        libraries: ['places'],
      });
      loader
        .load()
        .then((google) => {
          setGoogleAPILoaded(true);
        })
        .catch((error) => {
          console.error('Error loading Google Maps API:', error);
        });
    };
    loadGoogleMapsApi();
  }, []);
  return { isGoogleAPILoaded };
};
