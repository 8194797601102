import _ from 'lodash';
import moment from 'moment';

import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Listing, Picture } from 'models';
import {
  fetchListingsAsync,
  fetchListingAsync,
  fetchListingStatsAsync,
  fetchVirtualTourStatsAsync,
  getListingRawPicturesAsync,
  fetchDescriptionGeneratorAsync,
  saveDescriptionAsync,
  saveListingTagsAsync,
  saveFeedbackAsync,
  fetchPreviousDescriptionsAsync,
  fetchGPTPromptTagsAsync,
  sendMediaEmailToClientAsync,
  sendMediaSMSToClientAsync,
  fetchMediaNotificationAsync,
  fetchListingVideoTemplatesAsync,
  orderListingVideoAsync,
  updateFloorplanRoomAsync,
} from 'state/actions';
import { LOADING, IDLES } from 'state/constants';

export const useListings = (token: string, page: number = 1, searchInput: string = '') => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(LOADING.NONE);

  const { data, pageInfo } = useSelector((state: any) => {
    return state.listings;
  });

  const loadListings = async () => {
    const isIdle = IDLES.includes(loading);
    if (token && isIdle) {
      setLoading(LOADING.STARTED);
      await dispatch(fetchListingsAsync(token, page, searchInput));
      setLoading(LOADING.FINISHED);
    }
  };

  useEffect(() => {
    loadListings();
  }, [token, page, searchInput]);

  return { loading, pageInfo, listings: data };
};

export const useListing = (distToken: string, public_usage: boolean = false) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(LOADING.NONE);

  const listing = useSelector((state: any) => {
    return _.find(state.listings.data, (m: Listing) => {
      return m.distribution_token === distToken;
    });
  });

  const {
    postPictureCommentStatus,
    deletePictureCommentStatus,
    updateMLSStatus,
    isAuthorized,
    addedBrokerSwapPicturesStatus,
    addedPurchasedPicturesStatus,
    addedInvoiceStatus,
    addedFloorplanOrderStatus,
    addedVirtualServiceOrderStatus,
    floorplanOrders,
    virtualServiceOrders,
    requestCentrisUploadStatus,
  } = useSelector((state: any) => {
    return state.listings;
  });
  const loadListing = async () => {
    const isIdle = IDLES.includes(loading);
    if ((!listing || !listing.pictures) && isIdle) {
      setLoading(LOADING.STARTED);
      await dispatch(fetchListingAsync(distToken, public_usage));
      setLoading(LOADING.FINISHED);
    }
  };

  useEffect(() => {
    loadListing();
  }, [listing]);

  return {
    loading,
    listing,
    virtualServiceOrders,
    floorplanOrders,
    addedVirtualServiceOrderStatus,
    addedFloorplanOrderStatus,
    addedInvoiceStatus,
    addedPurchasedPicturesStatus,
    updateMLSStatus,
    isAuthorized,
    addedBrokerSwapPicturesStatus,
    postPictureCommentStatus,
    deletePictureCommentStatus,
    requestCentrisUploadStatus,
  };
};

export const useListingStats = (token: string, listingId: number) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(LOADING.NONE);

  const stats = useSelector((state: any) => {
    return _.find(state.listings.stats, (m) => {
      return m.id === listingId;
    });
  });

  const loadStats = async () => {
    const isIdle = IDLES.includes(loading);
    if (token && listingId && _.isEmpty(stats) && isIdle) {
      setLoading(LOADING.STARTED);
      await dispatch(fetchListingStatsAsync(token, listingId));
      setLoading(LOADING.FINISHED);
    }
  };

  useEffect(() => {
    loadStats();
  }, [token, stats, listingId]);

  return { loading, stats };
};

export const useVirtualTourStats = (token: string, listingId: number) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(LOADING.NONE);

  const vrStats = useSelector((state: any) => {
    return _.find(state.listings.vrStats, (m) => {
      return m.id === listingId;
    });
  });

  const loadVirtualTourStats = async () => {
    const isIdle = IDLES.includes(loading);
    if (token && listingId && _.isEmpty(vrStats) && isIdle) {
      const today = moment().format('YYYY-MM-DD');
      const yesterday = moment(today).subtract(1, 'day').format('YYYY-MM-DD');
      const twoMonthsAgo = moment(yesterday).subtract(2, 'months').format('YYYY-MM-DD');
      setLoading(LOADING.STARTED);
      await dispatch(fetchVirtualTourStatsAsync(token, listingId, twoMonthsAgo, yesterday));
      setLoading(LOADING.FINISHED);
    }
  };

  useEffect(() => {
    loadVirtualTourStats();
  }, [token, vrStats, listingId]);

  return { loading, vrStats };
};

export const useListingRawPictures = (shooting_id: number, listing_id: number, token: string) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(LOADING.NONE);

  const { rawPictures } = useSelector((state: any) => {
    return state.listings;
  });

  const loadRawPictures = async () => {
    const isIdle = IDLES.includes(loading);
    if (token && isIdle) {
      setLoading(LOADING.STARTED);
      await dispatch(getListingRawPicturesAsync(shooting_id, listing_id, token));
      setLoading(LOADING.FINISHED);
    }
  };

  useEffect(() => {
    loadRawPictures();
  }, [token, shooting_id, listing_id]);

  return { rawPictures, loading };
};

export const useDescriptionGenerator = (token: string, broker_id: number, listing_id: number) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(LOADING.NONE);

  const {
    generatedDescription,
    generatedDescriptionStatus,
    savedFeedbackStatus,
    savedEditedTextStatus,
    editedText,
    previousDescriptions,
    totalTokens,
  } = useSelector((state: any) => {
    return state.listings;
  });
  const loadPreviousDescriptions = async () => {
    await dispatch(fetchPreviousDescriptionsAsync(token, broker_id, listing_id));
  };
  useEffect(() => {
    loadPreviousDescriptions();
  }, [generatedDescriptionStatus]);
  const loadDescriptionGenerator = async (textLength: string, prompt: any, language: string) => {
    const isIdle = IDLES.includes(loading);
    if (token && isIdle) {
      setLoading(LOADING.STARTED);
      await dispatch(
        fetchDescriptionGeneratorAsync(token, broker_id, listing_id, prompt, textLength, language),
      );
      setLoading(LOADING.FINISHED);
    }
  };

  const saveDescription = async (gpt_usage_id: number, editedDescription: string) => {
    await dispatch(saveDescriptionAsync(token, gpt_usage_id, editedDescription));
    loadPreviousDescriptions();
  };
  const saveFeedback = async (gpt_usage_id: number, feedback: number) => {
    await dispatch(saveFeedbackAsync(token, gpt_usage_id, feedback));
  };
  return {
    generatedDescription,
    generatedDescriptionStatus,
    savedFeedbackStatus,
    savedEditedTextStatus,
    editedText,
    loading,
    loadDescriptionGenerator,
    saveDescription,
    saveFeedback,
    previousDescriptions,
    totalTokens,
    loadPreviousDescriptions,
  };
};

export const useGPTPromptTags = (
  token: string,
  listing_id: number,
  lat: number,
  lng: number,
  language: string,
) => {
  const dispatch = useDispatch();
  const [loadingTags, setLoadingTags] = useState(LOADING.NONE);
  const { nearbyPlaces, customPropertyTags, customFeatureTags } = useSelector((state: any) => {
    return state.listings;
  });
  const loadGPTPromptTags = async () => {
    const isIdle = IDLES.includes(loadingTags);
    if (token && isIdle) {
      setLoadingTags(LOADING.STARTED);
      await dispatch(fetchGPTPromptTagsAsync(token, listing_id, lat, lng, language));
      setLoadingTags(LOADING.FINISHED);
    }
  };
  const saveListingTags = async (prompt) => {
    await dispatch(saveListingTagsAsync(token, listing_id, prompt));
  };
  useEffect(() => {
    loadGPTPromptTags();
  }, []);

  return {
    nearbyPlaces,
    customPropertyTags,
    customFeatureTags,
    loadingTags,
    loadGPTPromptTags,
    saveListingTags,
  };
};

export const useListingSendMedia = (token: string, listing_id: number, broker_id: number) => {
  const dispatch = useDispatch();
  const { sentEmailStatus, sentEmailData, sentSMSData, sentSMSStatus, mediaNotificationLogs } =
    useSelector((state: any) => {
      return state.listings;
    });
  const loadNotificationLogs = async () => {
    await dispatch(fetchMediaNotificationAsync(token, listing_id, broker_id));
  };
  useEffect(() => {
    loadNotificationLogs();
  }, []);
  const sendMediaEmail = async (
    email: string,
    client_firstname: string,
    client_lastname: string,
    client_lang: string,
    public_delivery_url: string,
  ) => {
    await dispatch(
      sendMediaEmailToClientAsync(
        token,
        client_firstname,
        client_lastname,
        email,
        client_lang,
        listing_id,
        broker_id,
        public_delivery_url,
      ),
    );
    loadNotificationLogs();
  };
  const sendMediaSMS = async (
    client_firstname: string,
    client_last_name: string,
    phone_number: string,
    client_lang: string,
    public_delivery_url: string,
  ) => {
    await dispatch(
      sendMediaSMSToClientAsync(
        token,
        client_firstname,
        client_last_name,
        phone_number,
        client_lang,
        listing_id,
        broker_id,
        public_delivery_url,
      ),
    );
    loadNotificationLogs();
  };

  return {
    sendMediaEmail,
    sentEmailStatus,
    sentEmailData,
    sendMediaSMS,
    sentSMSData,
    sentSMSStatus,
    mediaNotificationLogs,
  };
};

export const useListingVideoTemplates = (token: string, listingId: number) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(LOADING.NONE);
  const [state, setState] = useState({
    ordered: false,
    ordering: LOADING.NONE,
  });
  const { videoTemplates, orderedListingVideoStatus } = useSelector((state: any) => {
    return state.listings;
  });

  const loadVideoTemplates = async () => {
    const isIdle = IDLES.includes(loading);
    if (token && listingId && isIdle) {
      setLoading(LOADING.STARTED);
      await dispatch(fetchListingVideoTemplatesAsync(token));
      setLoading(LOADING.FINISHED);
    }
  };
  const orderListingVideo = async (templateId: number, pictures: Picture[]) => {
    setState({ ordered: false, ordering: LOADING.STARTED });
    await dispatch(orderListingVideoAsync(token, listingId, templateId, pictures));
    setState({ ordered: true, ordering: LOADING.FINISHED });
  };

  return {
    loading,
    videoTemplates,
    orderListingVideo,
    loadVideoTemplates,
    orderedListingVideoStatus,
    ...state,
  };
};

export const useListingRoom = (token: string) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    updated: false,
    updating: LOADING.NONE,
  });

  const { uploadedRoomFloorTypeStatus } = useSelector((state: any) => {
    return state.listings;
  });

  const updateFloorplanRoom = async (room_id: number, floor_type: string) => {
    setState({ updated: false, updating: LOADING.STARTED });
    await dispatch(updateFloorplanRoomAsync(token, room_id, floor_type));
    setState({ updated: true, updating: LOADING.FINISHED });
  };

  return { uploadedRoomFloorTypeStatus, updateFloorplanRoom, ...state };
};
