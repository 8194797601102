import { IAmenity } from 'types';
import { FR, HOUSE_MIN_ROOMS } from 'config/constants';
import ShootingPackage from './ShootingPackage';

class ShootingParams {
  id: number;

  client_email: string;

  client_name: string;

  client_phone: string;

  client_preferred_language: string = FR;

  amenities: string;

  package_id: number;

  room_count: number;

  shooting_id: number;

  shooting_package_id: number;

  shooting_package: ShootingPackage = new ShootingPackage();

  constructor(data: object = {}) {
    Object.assign(this, data);

    if (this.shooting_package) {
      this.shooting_package = new ShootingPackage(this.shooting_package);
    }

    if (!this.client_preferred_language) {
      this.client_preferred_language = FR;
    }
  }

  getAmenities(): IAmenity[] {
    const { amenities } = this;
    if (amenities) {
      return JSON.parse(amenities) || [];
    }
    return [];
  }

  getStringAmenities(): String {
    const { amenities } = this;
    if (amenities) {
      return amenities || '';
    }
    return '';
  }
}

export default ShootingParams;
