import _, { remove } from 'lodash';

import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { Shooting } from 'models';
import {
  fetchShootings,
  fetchAssignedShootings,
  fetchShootingQueue,
  fetchShootingData,
  fetchDistanceFee,
  fetchShootingsFromTech,
  fetchShootingsFromPostTech,
  fetchUndeliveredShootings,
  generateInvoice,
  updateShootingService,
  fetchAllServiceOffers,
  fetchAllAvailabiliesServiceOffers,
  getUserShootings,
  fetchAllShootingPackages,
  saveCustomServiceOffer,
  fetchAllCustomServiceOffers,
  deleteServiceOffer,
  fetchAllServiceOfferItems,
  fetchDailyShootingActivity,
  fetchAllTechLocksForTheDay,
  updateTechStatus,
  updateShootingParams,
  recalculateDistances,
  assignProdShooting,
  changeShootingBillable,
  reinitializeShooting,
} from 'apis';

const initialState = {
  data: [],
  ShootingsFromTech: [],
  ShootingsFromPostTech: [],
  undeliveredShootings: [],
  serviceOffers: [],
  searchResult: [],
  pageTotalCountForSearch: 1,
  pageTotalCount: 1,
  saveCustomServiceOfferStatus: null,
  deleteShootingOfferStatus: null,
  customServiceOffers: [],
  serviceOfferItems: [],
  allServiceOffers: [],
  dailyShootingActivity: [],
  allTechLocksForTheDay: [],
  savedShootingParamsStatus: null,
  reinitializeShootingStatus: null,
  updateTechLockStatus: null,
  recalculateDistancesStatus: null,
  assignProdShootingStatus: null,
};

function sortShootings(shootings: Shooting[]) {
  return _.orderBy(
    shootings,
    [
      'hdrs_proc_uploaded',
      (m: Shooting) => {
        return m.post_production_priority || 0;
      },
      'scheduleddate',
    ],
    ['asc', 'desc', 'desc'],
  );
}

export const shootingsSlice = createSlice({
  name: 'shootings',
  initialState,
  reducers: {
    initialize: (state) => {
      state.data = [];
      state.ShootingsFromTech = [];
      state.ShootingsFromPostTech = [];
      state.undeliveredShootings = [];
      state.searchResult = [];
      state.serviceOffers = [];
      state.pageTotalCount = 1;
      state.pageTotalCountForSearch = 1;
      state.saveCustomServiceOfferStatus = null;
      state.deleteShootingOfferStatus = null;
      state.customServiceOffers = [];
      state.serviceOfferItems = [];
      state.allServiceOffers = [];
      state.dailyShootingActivity = [];
      state.allTechLocksForTheDay = [];
      state.savedShootingParamsStatus = null;
      state.reinitializeShootingStatus = null;
      state.updateTechLockStatus = null;
      state.recalculateDistancesStatus = null;
      state.assignProdShootingStatus = null;
    },
    addShootings: (state, action) => {
      state.data.unshift(..._.compact(action.payload));
      state.data = _.uniqBy(state.data, (m: Shooting) => {
        return m.id;
      });
      state.data = sortShootings(state.data);
    },
    updateShooting: (state, action) => {
      const shooting = action.payload;
      const index = _.findIndex(state.data, (m: Shooting) => {
        return m.id === shooting.id;
      });
      if (index === -1) {
        state.data.push(action.payload);
      } else {
        const oldShooting = _.cloneDeep(state.data[index]);
        Object.keys(shooting).forEach((key) => {
          return shooting[key] === undefined && delete shooting[key];
        });
        // Replace the array entirely since merge won't replace the array elements
        Object.keys(shooting).forEach((key) => {
          if (Array.isArray(shooting[key])) {
            oldShooting[key] = shooting[key]; // Replace the array entirely
          }
        });
        _.merge(oldShooting, shooting);
        state.data[index] = oldShooting;
      }
      state.data = sortShootings(state.data);
    },
    removeShooting: (state, action) => {
      state.data = state.data.filter((m: Shooting) => {
        return m.id !== action.payload;
      });
    },
    getShootingsFromTech: (state, action) => {
      state.ShootingsFromTech = action.payload;
    },
    getShootingsFromPostTech: (state, action) => {
      state.ShootingsFromPostTech = action.payload;
    },
    getUndeliveredShootings: (state, action) => {
      state.undeliveredShootings = action.payload.undeliveredShootings;
      state.pageTotalCount = action.payload.pageTotalCount;
    },
    getSearchResult: (state, action) => {
      state.searchResult = action.payload.undeliveredShootings;
      state.pageTotalCountForSearch = action.payload.pageTotalCount;
    },
    getServiceOffers: (state, action) => {
      state.serviceOffers = action.payload.data;
    },
    setSaveCustomServiceOfferStatus: (state, action) => {
      state.saveCustomServiceOfferStatus = action.payload;
    },
    getAllCustomServiceOffers: (state, action) => {
      state.customServiceOffers = action.payload;
    },
    getAllServiceOffers: (state, action) => {
      state.allServiceOffers = action.payload;
    },
    getAllServiceOfferItems: (state, action) => {
      state.serviceOfferItems = action.payload;
    },
    removeCustomServiceOffer: (state, action) => {
      state.customServiceOffers = state.customServiceOffers.filter((m) => {
        return m.id !== action.payload;
      });
    },
    setDeleteShootingOfferStatus: (state, action) => {
      state.deleteShootingOfferStatus = action.payload;
    },
    setDailyShootingActivity: (state, action) => {
      state.dailyShootingActivity = action.payload;
    },
    setAllTechLocksForTheDay: (state, action) => {
      state.allTechLocksForTheDay = action.payload;
    },
    setSavedShootingParamsStatus: (state, action) => {
      state.savedShootingParamsStatus = action.payload;
    },
    setReinitializeShootingStatus: (state, action) => {
      state.reinitializeShootingStatus = action.payload;
    },
    setUpdateTechLockStatus: (state, action) => {
      state.updateTechLockStatus = action.payload;
    },
    setRecalculateDistancesStatus: (state, action) => {
      state.recalculateDistancesStatus = action.payload;
    },
    setAssignProdShootingStatus: (state, action) => {
      state.assignProdShootingStatus = action.payload;
    },
  },
});

// Actions
export const {
  initialize,
  addShootings,
  updateShooting,
  removeShooting,
  getShootingsFromTech,
  getShootingsFromPostTech,
  getUndeliveredShootings,
  getServiceOffers,
  getSearchResult,
  setSaveCustomServiceOfferStatus,
  getAllCustomServiceOffers,
  getAllServiceOfferItems,
  getAllServiceOffers,
  removeCustomServiceOffer,
  setDeleteShootingOfferStatus,
  setDailyShootingActivity,
  setAllTechLocksForTheDay,
  setSavedShootingParamsStatus,
  setReinitializeShootingStatus,
  setUpdateTechLockStatus,
  setRecalculateDistancesStatus,
  setAssignProdShootingStatus,
} = shootingsSlice.actions;

// Thunks
export const fetchShootingsAsync = (token: string) => {
  return async (dispatch: Dispatch) => {
    const shootings = await fetchShootings(token);
    if (shootings.length) {
      dispatch(addShootings(shootings));
    }
  };
};

export const fetchAssignedShootingsAsync = (token: string) => {
  return async (dispatch: Dispatch) => {
    const shootings = await fetchAssignedShootings(token);
    if (shootings.length) {
      dispatch(addShootings(shootings));
    }
  };
};

export const fetchShootingQueueAsync = (token: string) => {
  return async (dispatch: Dispatch) => {
    const shootings = await fetchShootingQueue(token);
    if (shootings.length) {
      dispatch(addShootings(shootings));
    }
  };
};

export const fetchShootingAsync = (token: string, sId: number) => {
  return async (dispatch: Dispatch) => {
    const shooting = await fetchShootingData(token, sId);
    if (shooting) {
      dispatch(updateShooting(shooting));
    }
  };
};

export const fetchDistanceFeeAsync = (
  token: string,
  shootingId: number,
  brokerId: number,
  lat: string,
  lng: string,
) => {
  return async (dispatch: Dispatch) => {
    const shooting = await fetchDistanceFee(token, brokerId, lat, lng);
    if (shooting) {
      shooting.id = shootingId;
      dispatch(updateShooting(shooting));
    }
  };
};

export const fetchShootingsFromTechAsync = (
  user_id: number,
  startDate: string,
  endDate: string,
  assigned: any,
  token: string,
) => {
  return async (dispatch: Dispatch) => {
    const shootings = await fetchShootingsFromTech(user_id, startDate, endDate, assigned, token);
    if (shootings) {
      dispatch(getShootingsFromTech(shootings));
    }
  };
};

export const fetchShootingsFromPostTechAsync = (
  tech_id: number,
  start_date: string,
  end_date: string,
  token: string,
) => {
  return async (dispatch: Dispatch) => {
    const shootings = await fetchShootingsFromPostTech(tech_id, start_date, end_date, token);
    if (shootings) {
      dispatch(getShootingsFromPostTech(shootings));
    }
  };
};

export const fetchUndeliveredShootingsAsync = (
  token: string,
  searchInput = '',
  page?: number,
  loadAll: boolean = false,
) => {
  return async (dispatch: Dispatch) => {
    const undeliveredShootings = await fetchUndeliveredShootings(token, searchInput, page, loadAll);
    if (undeliveredShootings) {
      dispatch(getUndeliveredShootings(undeliveredShootings));
    }
  };
};

export const fetchSearchResultAsync = (
  token: string,
  searchInput = '',
  page?: number,
  loadAll: boolean = false,
) => {
  return async (dispatch: Dispatch) => {
    const undeliveredShootings = await fetchUndeliveredShootings(token, searchInput, page, loadAll);
    if (undeliveredShootings) {
      dispatch(getSearchResult(undeliveredShootings));
    }
  };
};

export const generateInvoiceAsync = (token: string, shooting_id: number) => {
  return async (dispatch: Dispatch) => {
    const generateShootingInvoice = await generateInvoice(token, shooting_id);
  };
};
export const fetchServiceOffersAsync = (token: string) => {
  return async (dispatch: Dispatch) => {
    const serviceOffers = await fetchAllServiceOffers(token);
    if (serviceOffers) {
      dispatch(getServiceOffers(serviceOffers));
    }
  };
};
export const fetchAllServiceOffersAsync = (token: string) => {
  return async (dispatch: Dispatch) => {
    const allServiceOffers = await fetchAllAvailabiliesServiceOffers(token);
    if (allServiceOffers) {
      dispatch(getAllServiceOffers(allServiceOffers));
    }
  };
};
export const fetchUserShootingsAsync = (
  user_id: number,
  date_range: any,
  is_admin = false,
  token: string,
) => {
  return async (dispatch: Dispatch) => {
    const shootings = await getUserShootings(user_id, date_range, is_admin, token);
    if (shootings) {
      dispatch(getShootingsFromTech(shootings));
    }
  };
};
export const fetchAllServiceOfferItemsAsync = (token: string) => {
  return async (dispatch: Dispatch) => {
    const serviceOfferItems = await fetchAllServiceOfferItems(token);
    if (serviceOfferItems) {
      dispatch(getAllServiceOfferItems(serviceOfferItems));
    }
  };
};
export const saveCustomServiceOfferAsync = (
  token: string,
  id: number,
  selectedSubscription: number,
  selectedServiceOfferHDRItem: number,
  selectedServiceOffers: number[],
  price: number,
  freelancer_prod_commission: number,
  processing_time_in_minutes: number,
  zoho_item_id: string,
  display_name: string,
  display_name_fr: string,
  description: string,
  description_fr: string,
  selectedBrokers: number[],
  selectedPhotographers: number[],
  available: boolean,
  gf_clause: boolean,
  is_global: boolean,
  recommendation_max: number,
) => {
  return async (dispatch: Dispatch) => {
    const res = await saveCustomServiceOffer(
      token,
      id,
      selectedSubscription,
      selectedServiceOfferHDRItem,
      selectedServiceOffers,
      price,
      freelancer_prod_commission,
      processing_time_in_minutes,
      zoho_item_id,
      display_name,
      display_name_fr,
      description,
      description_fr,
      selectedBrokers,
      selectedPhotographers,
      available,
      gf_clause,
      is_global,
      recommendation_max,
    );
    if (res) {
      dispatch(setSaveCustomServiceOfferStatus(res));
    }
  };
};
export const fetchAllCustomServiceOffersAsync = (token: string) => {
  return async (dispatch: Dispatch) => {
    const customServiceOffers = await fetchAllCustomServiceOffers(token);
    if (customServiceOffers) {
      dispatch(getAllCustomServiceOffers(customServiceOffers));
    }
  };
};
export const deleteServiceOfferAsync = (token: string, id: number) => {
  return async (dispatch: Dispatch) => {
    const res = await deleteServiceOffer(token, id);
    if (res) {
      dispatch(removeCustomServiceOffer(id));
      dispatch(setDeleteShootingOfferStatus(res));
    }
  };
};

export const fetchDailyShootingActivityAsync = (token: string, timestamp: string) => {
  return async (dispatch: Dispatch) => {
    const dailyShootingActivity = await fetchDailyShootingActivity(token, timestamp);
    if (dailyShootingActivity) {
      dispatch(setDailyShootingActivity(dailyShootingActivity));
    }
  };
};

export const fetchAllTechLocksForTheDayAsync = (token: string, date: string) => {
  return async (dispatch: Dispatch) => {
    const allTechLocksForTheDay = await fetchAllTechLocksForTheDay(token, date);
    if (allTechLocksForTheDay) {
      dispatch(setAllTechLocksForTheDay(allTechLocksForTheDay));
    }
  };
};

export const updateTechStatusAsync = (
  token: string,
  techId: number,
  is_locked: boolean,
  date: string,
) => {
  return async (dispatch: Dispatch) => {
    const res = await updateTechStatus(token, techId, is_locked, date);
    if (res && res.status === 200) {
      dispatch(setUpdateTechLockStatus(200));
    } else {
      dispatch(setUpdateTechLockStatus(500));
    }
    dispatch(setUpdateTechLockStatus(null));
  };
};

export const saveShootingParamsAsync = (
  token: string,
  shooting_id: number,
  scheduleddate: string,
  room_count: number,
  package_id?: number,
) => {
  return async (dispatch: Dispatch) => {
    const res = await updateShootingParams(
      token,
      shooting_id,
      scheduleddate,
      room_count,
      package_id,
    );
    if (res && res.status === 200) {
      dispatch(setSavedShootingParamsStatus(200));
    } else {
      dispatch(setSavedShootingParamsStatus(500));
    }
  };
};

export const recalculateDistancesAsync = (token: string, date: string) => {
  return async (dispatch: Dispatch) => {
    const res = await recalculateDistances(token, date);
    if (res && res.status === 200) {
      dispatch(setRecalculateDistancesStatus(200));
    } else {
      dispatch(setRecalculateDistancesStatus(500));
    }
  };
};

export const assignProdShootingAsync = (
  token: string,
  shooting_id: number,
  tech_id: number,
  force_assigned: boolean,
) => {
  return async (dispatch: Dispatch) => {
    const res = await assignProdShooting(token, shooting_id, tech_id, force_assigned);
    if (res && res.status === 200) {
      dispatch(setAssignProdShootingStatus(200));
    } else {
      dispatch(setAssignProdShootingStatus(500));
    }
  };
};

export const changeShootingBillableAsync = (
  token: string,
  shooting_id: number,
  billable: boolean,
) => {
  return async (dispatch: Dispatch) => {
    await changeShootingBillable(token, shooting_id, billable);
  };
};

export const reinitializeShootingAsync = (token: string, shooting_id: number) => {
  return async (dispatch: Dispatch) => {
    const res = await reinitializeShooting(token, shooting_id);
    if (res && res.status === 200) {
      dispatch(setReinitializeShootingStatus(200));
    } else {
      dispatch(setReinitializeShootingStatus(500));
    }
  };
};

export default shootingsSlice.reducer;
