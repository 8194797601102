/* eslint-disable no-new */
import classNames from 'classnames';

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { GOOGLE_MAPS_API_KEY } from 'config/constants';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Loader } from '@googlemaps/js-api-loader';

const useStyles = makeStyles({
  mapBox: {
    position: 'relative',
    width: '100%',
    height: 200,
  },
  mapBoxContent: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    height: 200,
  },
});

interface Props {
  className: string;
  lat: number;
  lng: number;
  zoom?: number;
}

function HvGoogleMap({ className, lat, lng, zoom }: Props) {
  const styles = useStyles();
  const mapRef = useRef(null);

  useEffect(() => {
    const loader = new Loader({
      apiKey: GOOGLE_MAPS_API_KEY,
      version: 'weekly',
      libraries: ['places'],
    });
    const mapOptions = {
      center: {
        lat,
        lng,
      },
      zoom: zoom || 11,
    };
    loader
      .load()
      .then((google) => {
        const map = new google.maps.Map(mapRef.current, mapOptions);
        new google.maps.Marker({
          position: { lat, lng },
          map,
        });
      })
      .catch((error) => {
        console.error('Error loading Google Maps API:', error);
      });
  }, [lat, lng, zoom]);

  return (
    <Box className={classNames(className, styles.mapBox)}>
      <Box className={styles.mapBoxContent}>
        <div ref={mapRef} style={{ width: '100%', height: '100%' }} />
      </Box>
    </Box>
  );
}

HvGoogleMap.defaultProps = {
  className: null,
};

HvGoogleMap.propTypes = {
  className: PropTypes.string,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
};

export default HvGoogleMap;
