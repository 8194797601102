import { ENG } from 'config/constants';
import ServiceOfferItemMapping from './ServiceOfferItemMapping';
import RelatedServiceOffer from './RelatedServiceOffer';

class ServiceOfferDetails {
  id?: number;

  available?: boolean;

  can_be_standalone?: boolean;

  description?: string;

  description_fr?: string;

  display_name?: string;

  display_name_fr?: string;

  freelancer_prod_commission?: number;

  gf_clause?: string;

  increment_by?: number;

  is_global?: boolean;

  key_name?: string;

  min_app_version?: string;

  order_index?: number;

  price?: number;

  processing_time_in_minutes?: number;

  require_video_template?: boolean;

  require_mls?: boolean;

  subscription?: any;

  subscription_id?: number;

  users?: number[] = [];

  zoho_item_id?: string;

  category?: any;

  example?: any;

  items?: ServiceOfferItemMapping[];

  recommendation_max?: number;

  is_recommended: boolean;

  service_items: any;

  related_service_offer: RelatedServiceOffer;

  log?: [];

  children?: any;

  blue_hour: boolean;

  is_ai_service?: boolean;

  promotion: any;

  constructor(data: object = {}) {
    Object.assign(this, data);
  }

  getDisplayName(language: string): string {
    const { display_name, display_name_fr } = this;
    if (language === ENG) {
      return display_name;
    }
    return display_name_fr;
  }
}

export default ServiceOfferDetails;
